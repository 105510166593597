<template>
  <div class="doctor-list-components">
    <div
      class="doctor-list-item"
      v-for="(item, index) in dataList"
      :key="index"
      @click="handleItem(item)"
    >
      <div class="flex-row-center flex-align-center doctor-ranking">
        <img
          class="ranking-icon"
          v-if="index == 0"
          src="../../assets/ranking/top_one@3x.png"
          width="100%"
        />
        <img
          class="ranking-icon"
          v-if="index == 1"
          src="../../assets/ranking/top_two@3x.png"
          width="100%"
        />
        <img
          class="ranking-icon"
          v-if="index == 2"
          src="../../assets/ranking/top_three@3x.png"
          width="100%"
        />
        <div class="ranking-number" v-if="index >= 3">
          {{ index + 1 < 10 ? "0" + (index + 1) : index + 1 }}
        </div>
      </div>
      <div class="doctor-list-item-temp">
        <div class="flex-row flex-align-start">
          <img class="doctor-avatar" :src="item.thumb" />
          <div class="item-temp">
            <div class="flex-row-between  flex-align-center doctor-details">
              <div class="flex-row flex-align-center">
                <div class="doctor-title">{{ item.name }}</div>
                <div class="doctor-level">{{ item.title }}</div>
              </div>
            </div>
            <div class="flex-row flex-align-center hospital-details">
              <div class="hospital-name">{{ item.hos_name }}</div>
              <div class="department-name" v-if="item.is_duodian == 1">
                多点执业
              </div>
            </div>
            <!-- <div class="flex-row flex-align-center tags-list">
            <div class="tags-item" v-show="item.yibao">{{ item.yibao }}</div>
            <div class="tags-item" style="background: #50c0d4;" v-show="item.type">{{ item.type }}</div>
            <div class="tags-item" :style="{ background: item.tag_color }" v-show="item.tag">{{ item.tag }} </div>
          </div> -->
          </div>
        </div>
        <div class="flex-row flex-row-start doctor-introduce">
          <div class="introduce-title">擅长：</div>
          <div class="introduce-context">{{ item.goodat }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleItem(item) {
      this.$emit("handleItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.doctor-list {
  &-item {
    // height: 249px;
    padding: 10px;
    height: 270px;
    margin-top: 70px;
    position: relative;
    height: 360px;
    background: rgba(255, 255, 255, 0.27);
    border: 1px solid #ffffff;
    border-radius: 20px;
    .doctor-ranking {
      position: absolute;
      top: -50px;
      width: 117px;
      height: 103px;
      margin-right: 20px;
      .ranking-icon {
        width: 117px;
        height: 103px;
      }
      .ranking-number {
        width: 80px;
        height: 80px;
        line-height: 80px;
        background: #d0b39d;
        border-radius: 16px;
        font-size: 44px;
        font-family: Advent Pro;
        font-weight: bold;
        color: #f3f4f4;
        text-align: center;
      }
    }
    &-temp {
      padding: 55px 22px 30px 24px;
      background: #fff;
      border-radius: 10px;
      .doctor-avatar {
        width: 116px;
        height: 120px;
        border-radius: 16px;
        border: 2px solid #f0f8fc;
      }
      .item-temp {
        width: 474px;
        margin-left: 24px;
      }
      .doctor-details {
        margin-top: 8px;
        .doctor-title {
          height: 32px;
          line-height: 32px;
          font-size: 32px;
          font-weight: bold;
          color: #1f1f1f;
        }
        .doctor-level {
          margin-left: 18px;
          height: 26px;
          line-height: 26px;
          font-size: 28px;
          font-weight: 500;
          color: #1f1f1f;
        }
      }
      .hospital-details {
        margin-top: 30px;
        .hospital-name {
          max-width: 270px;
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
          height: 28px;
          line-height: 28px;
          background: linear-gradient(-90deg, #EFDBBA 0%, #DABD8C 100%);
          border-radius: 22px;
          font-size: 28px;
          font-weight: 500;
          color: #6E3E30;
          padding: 8px 20px;
        }
        .department-name {
          text-align: center;
          width: 120px;
          margin-left: 16px;
          height: 24px;
          line-height: 24px;
          background: linear-gradient(-90deg, #EFDBBA 0%, #D9BB8A 100%);
          border-radius: 22px;
          font-size: 24px;
          font-weight: 500;
          color: #6E3E30;
          padding: 10px 14px;
        }
      }
    }
    .doctor-introduce {
      margin-top: 28px;
      width: 100%;

      .introduce-title {
        width: 90px;
        font-size: 28px;
        line-height: 40px;
        color: #3F271B;
        font-weight: bold;
      }
      .introduce-context {
        width: 550px;
        height: 118px;
        font-size: 28px;
        font-weight: 400;
        color: #927051;
        line-height: 40px;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
   &-item:first-child{
     margin-top: 0;
  }
}
</style>
