<template>
  <div class="doctor-list-components">
    <div
    class="doctor-list-item"
      
      v-for="(item, index) in dataList"
      :key="index"
      @click="handleItem(item)"
    >
      <div class="flex-row-center flex-align-center doctor-ranking"
      :class="index==0?'doctor-ranking-first':''">
        <img class="ranking-icon" v-if="index == 0" src="../../assets/ranking/follow-one@3x.png" width="100%" />
        <img class="ranking-icon" v-if="index > 0" src="../../assets/ranking/follow-two@3x.png" width="100%"/>
        <div class="flex-row flex-align-center ranking-temp">
          <div class="ranking-number">TOP{{index+1}}</div>
          <div class="ranking-follow">{{item.follows || 0}}人收藏</div>
        </div>
        <!-- <div class="ranking-number" v-if="index >= 3">
          {{ index + 1 < 10 ? "0" + (index + 1) : index + 1 }}
        </div> -->
      </div>
      <div class="doctor-list-item-temp">
        <div class="flex-row flex-align-start">
          <img class="doctor-avatar" :src="item.thumb" />
          <div class="item-temp">
            <div class="flex-row-between  flex-align-center doctor-details">
              <div class="flex-row flex-align-center">
                <div class="doctor-title">{{ item.name }}</div>
                <div class="doctor-level">{{ item.title }}</div>
              </div>
            </div>
            <div class="flex-row flex-align-center make-number-temp">
              <img class="number-icon" src="../../assets/ranking/follow-yuyue@3x.png" width="100%"/>
              <div class="number-text">预约量 {{ item.booking_nums }}</div>
            </div>
            <!-- <div class="flex-row flex-align-center tags-list">
            <div class="tags-item" v-show="item.yibao">{{ item.yibao }}</div>
            <div class="tags-item" style="background: #50c0d4;" v-show="item.type">{{ item.type }}</div>
            <div class="tags-item" :style="{ background: item.tag_color }" v-show="item.tag">{{ item.tag }} </div>
          </div> -->
          </div>
        </div>
        <div class="flex-row flex-row-start doctor-introduce">
          <div class="introduce-title">擅长:</div>
          <div class="introduce-context">{{ item.goodat }}</div>
        </div>
        <div class="flex-row flex-row-start doctor-context">
          <div class="introduce-title">上榜理由:</div>
          <div class="introduce-context">{{ item.rank_reasons }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleItem(item) {
      this.$emit("handleItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.doctor-list {
  &-item {
    // height: 249px;
    // height: 270px;
    margin-top: 20px;
    position: relative;
    // height: 360px;
    border-radius: 20px;
    
    .doctor-ranking {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 289px;
      height: 50px;
      .ranking-icon {
        position: absolute;
        width: 289px;
        height: 50px;
      }
       .ranking-temp{
        position: relative;
        width: 393px;
        height: 50px;
        .ranking-number{
          letter-spacing: 2px;
          position: absolute;
          display: inline-block;
          top: 0px;
          width: 126px;
          left: 0;
          height: 50px;
          line-height: 50px;
          font-size: 32px;
          font-weight: 400;
          color: #E3BD73;
          text-align: center;
        }
        .ranking-follow{
          position: absolute;
          display: inline-block;
          width: 156px;
          text-align: center;
          top: 10px;
          right: 0px;
          height: 24px;
          line-height: 24px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #EFCD94;
        }
      }
    }
    .doctor-ranking-first {
      position: absolute;
      top: -20px;
      left: -10px;
      width: 393px;
      height: 60px;
      .ranking-icon {
        width: 393px;
        height: 60px;
      }
      .ranking-temp{
        position: relative;
        width: 393px;
        height: 50px;
        top: -6px;
        .ranking-number{
          position: absolute;
          display: inline-block;
          top: 13px;
          width: 132px;
          left: 44px;
          height: 23px;
          line-height: 23px;
          font-size: 32px;
          font-weight: 400;
          color: #E3BD73;
          text-align: center;
        }
        .ranking-follow{
          position: absolute;
          display: inline-block;
          width: 166px;
          text-align: center;
          top: 10px;
          right: 4px;
          height: 24px;
          line-height: 24px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
    &-temp {
      padding: 58px 22px 30px 24px;
      background: #fff;
      border-radius: 16px;
      .doctor-avatar {
        width: 120px;
        height: 120px;
        border-radius: 16px;
        border: 2px solid #f0f8fc;
      }
      .item-temp {
        width: 474px;
        margin-left: 24px;
      }
      .doctor-details {
        margin-top: 4px;
        .doctor-title {
          height: 36px;
          line-height: 36px;
          font-size: 36px;
          font-weight: bold;
          color: #1F1F1F;
        }
        .doctor-level {
          margin-left: 18px;
          height: 30px;
          line-height: 30px;
          font-size: 30px;
          font-weight: 500;
          color: #1F1F1F;
        }
      }
      .make-number-temp{
        margin-top: 30px;
        height: 44px;
        background: #FFE7DC;
        border-radius: 22px;
        padding: 2px 28px 0 18px;
        display: inline-flex;
        .number-icon{
          width: 34px;
          height: 32px;
          display: inline-block;
        }
        .number-text{
          margin-left: 6px;
          height: 32px;
          font-size: 28px;
          font-family: PingFang SC;
          color: #6E3E30;
          line-height: 32px;
        }
      }
    }
    .doctor-introduce {
      margin-top: 28px;
      width: 100%;

      .introduce-title {
        width: 68px;
        font-size: 28px;
        line-height: 40px;
        color: #1f1f1f;
        font-weight: bold;
        position: relative;
        // box-shadow: 0 0 50px rgba(255,220,175,0.6) inset;
        background-image: linear-gradient(#FFF 60%, rgba(255,220,175,0.6) 30%);
      }
      .introduce-context {
        margin-left: 10px;
        width: 584px;
        font-size: 28px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .doctor-context{
      margin-top: 30px;
      width: 100%;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 40px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 28px;
      .introduce-title {
        width: 186px;
        font-size: 28px;
        line-height: 28px;
        height: 28px;
        color: #1f1f1f;
        font-weight: bold;
        display:inline;
        position: relative;
        background-image: linear-gradient(#FFF 60%, rgba(255,220,175,0.6) 30%);
      }
      .introduce-context {
        margin-left: 10px;
        width: 660px;
        display:inline;
        font-size: 28px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        
      }
    }
  }
   &-item:first-child{
     margin-top: 0;
  }
}
</style>
