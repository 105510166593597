import request from '../api/request'

//测评模块推荐医生
export function getDoctorsRecommend(params) {
  return request({
    url: 'ceping/getDoctorsRecommend',
    method: 'get',
    params
  })
}
