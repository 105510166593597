import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index/index'//首页
import BreastDetails from '../views/health/breastDetails'//乳腺自查
import BreastIntroduce from '../views/health/breastIntroduce'//乳腺指南
import QuestionTest from '../views/diabetes/questionTest'//糖尿病测试
import QuestionSuccess from '../views/diabetes/questionSuccess' //糖尿病测试结果
import DepressionTest from '../views/depression/depressionTest' //抑郁测试
import DepressionSuccess from '../views/depression/depressionSuccess' //抑郁测试结果
import DeficiencyTest from '../views/deficiency/deficiencyTest' //中医体质检测
import DeficiencySuccess from '../views/deficiency/deficiencySuccess' //中医体质检测结果
import RankingMake from '../views/ranking/make' //预约排行榜
import RankingRecommend from '../views/ranking/recommend' //患者推荐排行榜
import RankingEvaluate from '../views/ranking/evaluate' //好评排行
import RankingPopularity from '../views/ranking/popularity' //年度人气榜单
import RankingFollow from '../views/ranking/follow' //珍藏好医榜单
import RankingPraise from '../views/ranking/praise' //实力口碑榜单
import RankingList from '../views/ranking/list' //热门榜单
import ServerVideo from '../views/server/yyghw/no-video' //远程视频
import recruitPoster from '../views/recruit/poster' //招募医生专题
import recruitStep1 from '../views/recruit/step1' //医生基础信息
import recruitStep2 from '../views/recruit/step2' //医生资质认证
import recruitSuccess from '../views/recruit/success' //招募认证成功
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    meta:{
      title:'首页'
    },
    component: Index
  }, 
  {
    path: '/BreastDetails',
    name: 'BreastDetails',
    meta:{
      title:'乳腺自查'
    },
    component: BreastDetails
  }, 
  {
    path: '/BreastIntroduce',
    name: 'BreastIntroduce',
    meta:{
      title:'乳腺健康指南'
    },
    component: BreastIntroduce
  },
  {
    path: '/QuestionTest',
    name: 'QuestionTest',
    meta:{
      title:'2型糖尿病测试'
    },
    component: QuestionTest
  },
  {
    path: '/QuestionSuccess',
    name: 'QuestionSuccess',
    meta:{
      title:'2型糖尿病测试'
    },
    component: QuestionSuccess
  },
  {
    path: '/DepressionTest',
    name: 'DepressionTest',
    meta:{
      title:'抑郁测试'
    },
    component: DepressionTest
  },
  {
    path: '/DepressionSuccess',
    name: 'DepressionSuccess',
    meta:{
      title:'结果分析'
    },
    component: DepressionSuccess
  },
  {
    path: '/DeficiencyTest',
    name: 'DeficiencyTest',
    meta:{
      title:'气虚体质测评'
    },
    component: DeficiencyTest
  },
  {
    path: '/DeficiencySuccess',
    name: 'DeficiencySuccess',
    meta:{
      title:'气虚体质测评结果'
    },
    component: DeficiencySuccess
  },
  {
    path: '/ranking/rankingMake',
    name: 'RankingMake',
    meta:{
      title:'预约榜单'
    },
    component: RankingMake
  },
  {
    path: '/ranking/rankingRecommend',
    name: 'RankingRecommend',
    meta:{
      title:'患者优选排行榜'
    },
    component: RankingRecommend
  },
  {
    path: '/ranking/rankingEvaluate',
    name: 'RankingEvaluate',
    meta:{
      title:'好评排行'
    },
    component: RankingEvaluate
  },
  {
    path: '/ranking/RankingPopularity',
    name: 'RankingPopularity',
    meta:{
      title:'年度人气榜单'
    },
    component: RankingPopularity
  },
  {
    path: '/ranking/RankingFollow',
    name: 'RankingFollow',
    meta:{
      title:'珍藏好医'
    },
    component: RankingFollow
  },
  {
    path: '/ranking/RankingPraise',
    name: 'RankingPraise',
    meta:{
      title:'实力口碑榜'
    },
    component: RankingPraise
  },
  {
    path: '/ranking/RankingList',
    name: 'RankingList',
    meta:{
      title:'热门榜单'
    },
    component: RankingList
  },
  {
    path: '/server/ServerVideo',
    name: 'ServerVideo',
    meta:{
      title:'远程视频问诊'
    },
    component: ServerVideo
  },
  {
    path: '/recruitPoster',
    name: 'recruitPoster',
    meta:{
      title:'招募医生专题'
    },
    component: recruitPoster
  },
  {
    path: '/recruitStep1',
    name: 'recruitStep1',
    meta:{
      title:'医生基础信息'
    },
    component: recruitStep1
  },
  {
    path: '/recruitStep2',
    name: 'recruitStep2',
    meta:{
      title:'医生资质认证'
    },
    component: recruitStep2
  },
  {
    path: '/recruitSuccess',
    name: 'recruitSuccess',
    meta:{
      title:'提交成功'
    },
    component: recruitSuccess
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, saveTop){
    if (saveTop) {
        return saveTop;
    } else {
        return {x: 0, y: 0}
    }
}
})

export default router
