<template>
  <div class="flex-column flex-align-center depressionSuccess-content">
    <div class="depressionSuccess-header">您的抑郁测试结果为</div>
    <div class="depressionSuccess-section">
      <div class="flex-column flex-align-center depressionSuccess-section-answer">
        <div class="answer-title">{{ sumNumber }}<span>分</span></div>
        <div class="answer-content">
          {{ tempData.leval }}<br />
          {{ tempData.content }}
        </div>
        <div class="depressionSuccess-section-tips">数据来源：https://web.dtgh91.com/DepressionTest?ceping_id=100003</div>
      </div>
      <div class="depressionSuccess-section-doctor">
        <div class="
            flex-row-between flex-align-center
            depressionSuccess-section-doctor-header" v-if="dataList.length > 0">
          <div class="temp-title">优选擅长医生</div>
          <div class="flex-row flex-align-center temp-swiper" @click="handleSwitch">
            <img class="switch-icon" src="../../assets/depression/switch-icon.png" />
            <div class="switch-text">换一换</div>
          </div>
        </div>
        <div class="depressionSuccess-section-doctor-list">
          <div class="depressionSuccess-section-doctor-list-item" v-for="(item, index) in dataList" :key="index"
            @click="handleItem(item)">
            <div class="
                flex-row-between flex-align-center
                depressionSuccess-section-doctor-list-item-header
              ">
              <div class="flex-row flex-align-center item-header">
                <div class="doctor-avatar">
                  <img class="doctor-avatar" :src="item.thumb" width="100%"/>
                  <div class="doctor-duodian" v-if="item.is_duodian==1">多点执业</div>
                </div>
                <div class="flex-column flex-align-cnter doctor-temp">
                  <div class="flex-row flex-align-end name">
                    <span>{{ item.name }}</span>
                    <span class="title">{{ item.title }}</span>
                  </div>
                  <div class="flex-row flex-align-center level">
                    <div class="hos_name">{{ item.hos_name }}</div>
                    <div class="fac_name">{{ item.fac_name }}</div>
                  </div>
                </div>
              </div>
              <div class="make-button">去挂号</div>
            </div>
            <div class="flex-row flex-align-center tags-list">
              <div class="tags-item" v-show="item.yibao">
                {{ item.yibao }}
              </div>
              <div class="tags-item" style="background: #50c0d4;" v-show="item.type">
                {{ item.type }}
              </div>
              <div class="tags-item" style="background: #53d0f9;" v-show="item.is_remote == 1">视频门诊</div>
              <div class="tags-item" :style="{ background: item.tag_color }" v-show="item.tag">
                {{ item.tag }}
              </div>
            </div>
            <div class="doctor-goodat">
              {{ item.goodat }}
            </div>
          </div>
        </div>
      </div>
      <div style="height: 40px"></div>
    </div>
  </div>
</template>
<script>
import depressionData from "../../data/depressionData.json";
import { getDoctorsRecommend } from "../../api/doctorApi.js";
export default {
  data() {
    return {
      sumNumber: 0,
      tempData: {
        content: "",
        leval: "",
      },
      curPage: 1,
      totalPage: 1, // 总页数
      dataList: [],
      tempDataParams: {},
      formClient: "",
    };
  },
  mounted() {
    this.sumNumber = this.$route.query.sum;
    this.tempDataParams = this.$route.query;
    this.formClient = this.$route.query.formClient || "";
    document.body.style.backgroundColor = "#472EC7";
    this.getList();
    this.getDoctorsList();
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () { });
    });
  },
  methods: {
    getList() {
      const self = this;
      try {
        depressionData.answerList.forEach((item) => {
          if (item.number >= self.sumNumber) {
            self.tempData = item;
            throw new Error("success");
          }
        });
      } catch (e) {
        if (e.message == "success") {
          console.log("找到了");
        }
      }
    },
    getDoctorsList() {
      const self = this;
      this.tempDataParams["page"] = this.curPage;
      this.tempDataParams["pageNum"] = 2;
      getDoctorsRecommend(this.tempDataParams).then((res) => {
        self.totalPage = res.data.totalPage;
        self.dataList = res.data.dataList || [];
        console.log(">>", self.dataList);
      });
    },
    handleSwitch() {
      if (this.totalPage > this.curPage) {
        this.curPage++;
      } else {
        this.curPage = 1;
      }
      this.getDoctorsList();
    },
    handleItem(item) {
      if (this.formClient == "bjAndroid") {
        try {
          $App.getDataFormVue(item.doc_id_dt);
        } catch (e) {
          window.webkit.messageHandlers.doctor.postMessage({
            docid: item.doc_id_dt,
          });
        }
        return;
      } else if (this.formClient == "bjIOS") {
        window.webkit.messageHandlers.doctor.postMessage({
          docid: item.doc_id_dt,
        });
        return;
      } else if (this.formClient == "h5") {
        let url =
          this.FEServer +
          "doctor/details?doc_id=" +
          item.doc_id +
          "&order_source=ceping_" +
          this.tempDataParams.ceping_id;
        // window.location.replace(url);
        uni.navigateTo({
          url:
            "../doctor/details?doc_id=" +
            item.doc_id +
            "&order_source=ceping_" +
            this.tempDataParams.ceping_id,
        });
        return;
      }
      this.$nextTick(() => {
        // document.addEventListener('UniAppJSBridgeReady', function() {
        // 向 app 发送消息
        uni.postMessage({
          data: {
            action: "doctorDetails",
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=ceping_" +
              this.tempDataParams.ceping_id,
          },
        });
        if (this.tempDataParams.formClient == "wechat") {
          uni.navigateTo({
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=ceping_" +
              this.tempDataParams.ceping_id,
          });
        }
      });
      // })
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss">
.depressionSuccess {
  &-content {
    padding: 0 30px;
  }

  &-header {
    margin-top: 100px;
    height: 34px;
    line-height: 34px;
    font-size: 36px;
    font-weight: 500;
    color: #f7d82b;
  }

  &-section {
    &-tips{
      font-size: 24px;
      margin-top:30px ;
    }
    &-answer {
      margin-top: 72px;
      padding: 52px 20px 18px;
      background: #fff;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;

      // width: 100%;
      .answer-title {
        height: 48px;
        line-height: 48px;
        font-size: 60px;
        font-weight: 500;
        color: #ec5246;

        span {
          margin-left: 4px;
          font-size: 36px;
        }
      }

      .answer-content {
        margin-top: 52px;
        padding: 36px;
        background: #e7ecf4;
        border-radius: 20px;
        font-size: 28px;
        font-weight: 500;
        color: #302e2e;
        line-height: 46px;
      }
    }

    &-doctor {
      background: url("../../assets/depression/success-bg.png") no-repeat;
      background-size: 100%;
      width: 100%;
      height: 758px;

      &-header {
        padding: 0 28px;
        padding-top: 88px;

        .temp-title {
          height: 31px;
          line-height: 31px;
          font-size: 32px;
          font-weight: bold;
          color: #4a4a4a;
        }

        .temp-swiper {
          .switch-icon {
            width: 26px;
            height: 24px;
          }

          .switch-text {
            margin-left: 8px;
            height: 23px;
            line-height: 23px;
            font-size: 24px;
            font-weight: 500;
            color: #3ecd80;
          }
        }
      }

      &-list {
        margin: 0 10px;
        margin-top: 30px;

        &-item {
          padding: 0 20px;
          padding-bottom: 14px;
          margin-top: 20px;
          background: #f7f8fa;
          border-radius: 10px;

          &-header {
            padding-top: 24px;
            position: relative;

            .item-header {
              .doctor-avatar {
                width: 100px;
                height: 100px;
                background-color: #F7F8FA;
                border-radius: 50%;
                position: relative;

                .doctor-duodian {
                  position: absolute;
                  bottom: -14px;
                  width: 100px;
                  height: 30px;
                  line-height: 30px;
                  background: linear-gradient(-90deg, #D09762 0%, #D6AC84 100%);
                  border-radius: 15px;
                  text-align: center;
                  font-size: 20px;
                  color: #6D3E30;
                }
              }

              .doctor-temp {
                margin-left: 25px;

                .name {
                  height: 32px;
                  line-height: 32px;
                  font-size: 34px;
                  font-weight: bold;
                  color: #20201f;

                  .title {
                    font-size: 28px;
                    color: #1f1f1e;
                    margin-left: 24px;
                  }
                }

                .level {
                  margin-top: 18px;
                  height: 28px;
                  line-height: 28px;
                  font-size: 28px;
                  font-weight: 500;
                  color: #1f1f1e;

                  .hos_name {
                    max-width: 380px;
                    margin-right: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .fac_name {}
                }
              }
            }

            .make-button {
              position: absolute;
              right: 0px;
              top: 16px;
              text-align: center;
              width: 110px;
              height: 46px;
              background: #3ece80;
              border-radius: 30px;
              font-size: 26px;
              line-height: 46px;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
        &-item:first-child{
          margin-top: 0;
        }
        .tags-list {
          margin-top: 18px;
          margin-left: 126px;

          .tags-item {
            // width: 58px;
            background: #3ecd80;
            height: 32px;
            line-height: 32px;
            border-radius: 4px;
            padding: 0 10px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            border-radius: 4px;
            margin-right: 10px;
          }

          .yellow {
            background-color: #ff8618;
            color: #ffffff;
          }
        }

        .doctor-goodat {
          margin-left: 126px;
          margin-top: 18px;
          height: 68px;
          font-size: 28px;
          font-weight: 500;
          color: #5c616a;
          line-height: 34px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
