import request from "../api/request";
//榜单医生
export function getRankingDoctorList(params) {
  return request({
    url: "rank/getDetail",
    method: "get",
    params,
  });
}
//年度排行榜列表
export function getRankList(params) {
  return request({
    url: "rank/getRankList",
    method: "get",
    params,
  });
}
