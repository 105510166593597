<template>
  <div class="ranking-popularity-content">
    <div class="ranking-popularity-header">
      <img
        class="ranking-popularity-header-img"
        width="100%"
        :src="dataInfo.rank_pic?dataInfo.rank_pic:require('../../assets/ranking/top_popularity@3x.png')"
      />
      <div class="flex-row-between ranking-popularity-header-doctor">
        <div
          class="ranking-popularity-header-doctor-two"
          :class="index == 1 ? 'ranking-popularity-header-doctor-one' : ''"
          v-for="(top, index) in rankingList"
          :key="index"
          @click.stop="handleItem(top)"
        >
        <img
            width="100%"
            v-if="index == 0"
            src="../../assets/ranking/top_popularity-two.png"
          />
          <img
            width="100%"
            v-if="index == 1"
            src="../../assets/ranking/top_popularity-one.png"
          />
          <img
            width="100%"
            v-if="index == 2"
            src="../../assets/ranking/top_popularity-three.png"
          />
          <div class="doctor-details">
            <div class="flex-row flex-align-start doctor-details-temp">
              <img class="doctor-avatar" :src="top.thumb" />
              <div class="item-temp">
                <div class="doctor-title">{{ top.name }}</div>
                <div class="doctor-level" :class="top.title.length>=5?'doctor-level-more':''">{{ top.title }}</div>
              </div>
            </div>
            <div class="grade-details">
              <div class="flex-row flex-align-center grade-details-item">
                <div class="grade-details-item-title">预约量</div>
                <div class="grade-details-item-value">{{top.booking_nums}}</div>
              </div>
              <div class="flex-row flex-align-center grade-details-item">
                <div class="grade-details-item-title">热度</div>
                <div class="grade-details-item-value">{{top.likes}}</div>
              </div>
              <div class="flex-row flex-align-center grade-details-item">
                <div class="grade-details-item-title">专业</div>
                <div class="grade-details-item-value">{{top.score_skills*10}}</div>
              </div>
              <div class="flex-row flex-align-center grade-details-item">
                <div class="grade-details-item-title">口碑</div>
                <div class="grade-details-item-value">{{top.goods}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ranking-popularity-doctor">
      <popularity-ranking-doctor
        class="ranking-popularity-doctor-list"
        @handleItem="handleItem"
        :dataList="dataList"
      />
    </div>
  </div>
</template>
<script>
import PopularityRankingDoctor from "../../components/ranking/PopularityDoctorList.vue";
import { getRankingDoctorList } from "../../api/rankingApi.js";
export default {
  components: { PopularityRankingDoctor },
  data() {
    return {
      tempDataParams: {},
      formClient: "",
      dataList: [],
      rankingList: [],
      dataInfo: {
        rank_pic: "",
      },
    };
  },
  mounted() {
    this.tempDataParams = this.$route.query;
    this.formClient = this.$route.query.formClient || "";
    this.getDoctorsList();
    document.body.style.backgroundColor = "#fff";
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function() {});
    });
  },
  methods: {
    getDoctorsList() {
      const self = this;
      this.tempDataParams["page"] = this.curPage;
      this.tempDataParams["pageNum"] = 10;
      getRankingDoctorList(this.tempDataParams).then((res) => {
        let result = res.data.dataList || []
        self.dataList =  result.splice(3,result.length);
        self.dataInfo = res.data.dataInfo || { rank_pic: "" };
        document.title = self.dataInfo.title;
        let arr = result.slice(0, 3);
        self.rankingList = [arr[1],arr[0],arr[2]];
        console.log(self.rankingList);
      });
    },
    handleItem(item) {
      console.log("...",item)
      if (this.formClient == "bjAndroid") {
        try {
          $App.getDataFormVue(item.doc_id_dt);
        } catch (e) {
          window.webkit.messageHandlers.doctor.postMessage({
            docid: item.doc_id_dt,
          });
        }
        return;
      } else if (this.formClient == "bjIOS") {
        window.webkit.messageHandlers.doctor.postMessage({
          docid: item.doc_id_dt,
        });
        return;
      }else if(this.formClient == "h5"){
         let url = this.FEServer+"doctor/details?doc_id="+item.doc_id +"&order_source=rank_" + this.tempDataParams.rank_id;
          // window.location.replace(url) 
          uni.navigateTo({
            url:
              "../doctor/details?doc_id="+item.doc_id +"&order_source=rank_" + this.tempDataParams.rank_id
          });
          return
      }
      this.$nextTick(() => {
        // document.addEventListener('UniAppJSBridgeReady', function() {
        // 向 app 发送消息
        uni.postMessage({
          data: {
            action: "doctorDetails",
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=rank_" +
              this.tempDataParams.rank_id,
          },
        });
        if (this.tempDataParams.formClient == "wechat") {
          uni.navigateTo({
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=rank_" +
              this.tempDataParams.rank_id,
          });
        }
      });
      // })
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss" scoped>
.ranking-popularity {
  &-content {
  }
  &-header {
    position: relative;
    height: 790px;
    width: 100%;
    &-img {
      height: 790px;
      width: 100%;
    }
    &-doctor {
      position: absolute;
      bottom: 168px;
      width: 710px;
      padding: 0 20px;

      &-two,
      &-three {
        margin-top: 26px;
      }
      &-one,
      &-two,
      &-three {
        width: 230px;
        height: 312px;
        position: relative;
        img {
          width: 230px;
          height: 312px;
        }
        .doctor-details {
          position: absolute;
          top: 0;
          margin-top: 54px;
          padding: 0 14px 0 21px;
          .doctor-details-temp {
            .doctor-avatar {
              width: 80px;
              height: 80px;
              border-radius: 50%;
            }
            .item-temp {
              margin-left: 7px;
              margin-top: 8px;
              width: 114px;
              .doctor-title {
                height: 30px;
                font-size: 30px;
                font-weight: bold;
                color: #1b1a11;
                line-height: 30px;
              }
              .doctor-level {
                margin-top: 11px;
                height: 24px;
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #1b1a11;
                line-height: 24px;
              }
              .doctor-level-more{
                font-size: 20px;
              }
            }
          }
          .grade-details {
            margin-left: 13px;
            margin-top: 18px;
            .grade-details-item {
              margin-top: 12px;
              .grade-details-item-title {
                height: 24px;
                font-size: 24px;
                font-weight: 500;
                color: #666666;
                line-height: 24px;
                width: 80px;
              }
              .grade-details-item-value {
                margin-left: 14px;
                height: 26px;
                font-size: 26px;
                font-weight: bold;
                color: #605cde;
                line-height: 26px;
              }
            }
            .grade-details-item:first-child {
              margin-top: 0;
            }
          }
        }
      }
      &-one {
        margin-top: 0px;
      }
    }
  }
  &-doctor{
      position: relative;
      top: -72px;
  }
}
</style>
