<template>
  <div class="diabetes-questionSuccess-content">
    <div class="diabetes-questionSuccess-header">
      <div class="flex-row flex-align-center diabetes-questionSuccess-header-temp">
        <div class="column-line"></div>
        <div class="title">测试结果</div>
      </div>
      <div class="flex-row flex-align-center diabetes-questionSuccess-header-title">
        2型糖尿病自测结果评价
      </div>
      <div class="flex-column diabetes-questionSuccess-header-result">
        <div class="title">
          您的结果为<span>{{ tempDate.leval || "" }}</span>
        </div>
        <div>{{ tempDate.content || "" }}</div>
      </div>
      <div class="flex-column diabetes-questionSuccess-header-tips">
        <div class="title">温馨提示</div>
        <div class="content">
          由于个体的不同以及其他可能的影响因素，本测试不作为任何诊断依据，仅用于简单评估生活方式及遗传因素对2型糖尿病发病风险的影响。积极的测试结果意味着你可以维持现有的生活状态，或者调整至更健康的饮食、作息和运动习惯；而当测试结果在5分及以上时，则建议进行体检和注意定期体检，并及时调整生活方式来减少发病风险。
        </div>
      </div>
    </div>
    <div class="diabetes-questionSuccess-section" v-if="dataList.length > 0">
      <div class="flex-row-between flex-align-center diabetes-questionSuccess-section-header">
        <div class="header-title">优选擅长医生</div>
        <div class="flex-row-center flex-align-center switch-temp" @click="handleSwitch">
          <div class="flex-row-center flex-align-center switch-icon">
            <img width="100%" src="../../assets/\depression/switch-icon.png" />
          </div>
          <div class="switch-text">换一换</div>
        </div>
      </div>

      <div class="diabetes-questionSuccess-section-item" v-for="(docItem, docIndex) in dataList" :key="docIndex"
        @click="handleItem(docItem)">
        <div class="flex-row-between flex-align-center diabetes-questionSuccess-section-item-header">
          <div class="flex-row flex-align-center left-temp">
            <div class="doctor-avatar">
              <img class="doctor-avatar" :src="docItem.thumb" width="100%"/>
              <div class="doctor-duodian" v-if="docItem.is_duodian==1">多点执业</div>
            </div>
            <div class="flex-column flex-align-start doctor-details">
              <div class="flex-row flex-align-end name">
                <span>{{ docItem.name }}</span>
                <span class="title">{{ docItem.title }}</span>
              </div>
              <div class="flex-row flex-align-center level">
                <div class="hos_name">{{ docItem.hos_name }}</div>
                <div class="fac_name">{{ docItem.fac_name }}</div>
              </div>
            </div>
          </div>
          <div class="flex-row flex-align-center right-temp">
            <div class="make-button">去挂号</div>
          </div>
        </div>
        <div class="flex-row flex-align-center tags-list">
          <div class="tags-item" v-show="docItem.yibao">
            {{ docItem.yibao }}
          </div>
          <div class="tags-item" style="background: #50c0d4;" v-show="docItem.type">
            {{ docItem.type }}
          </div>
          <div class="tags-item" style="background: #53d0f9;" v-show="docItem.is_remote == 1">视频门诊</div>
          <div class="tags-item" :style="{ background: docItem.tag_color }" v-show="docItem.tag">
            {{ docItem.tag }}
          </div>
        </div>
        <div class="diabetes-questionSuccess-section-item-introduce">
          {{ docItem.goodat }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import questionData from "../../data/diabetesData.json";
import { getDoctorsRecommend } from "../../api/doctorApi.js";
export default {
  data() {
    return {
      tempDate: {
        leval: "",
        content: "",
      },
      curPage: 1,
      totalPage: 1, // 总页数
      dataList: [],
      tempDataParams: {},
      formClient: "",
    };
  },
  mounted() {
    this.sumNumber = this.$route.query.sum;
    this.tempDataParams = this.$route.query;
    this.formClient = this.$route.query.formClient || "";
    this.getList();
    document.body.style.backgroundColor = "#EBF0F9";
    this.getDoctorsList();
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () { });
    });
  },
  methods: {
    getList() {
      const self = this;
      if (self.sumNumber < 7) {
        self.tempDate = questionData.answerList[0];
      } else if (self.sumNumber <= 11) {
        self.tempDate = questionData.answerList[1];
      } else if (self.sumNumber <= 14) {
        self.tempDate = questionData.answerList[2];
      } else if (self.sumNumber <= 20) {
        self.tempDate = questionData.answerList[3];
      } else if (self.sumNumber > 20) {
        self.tempDate = questionData.answerList[4];
      }
      // questionData.answerList.forEach((item) => {
      //   if (item.number < self.sumNumber) {
      //     self.tempDate = item;
      //     return;
      //   }
      // });
    },
    getDoctorsList() {
      const self = this;
      this.tempDataParams["page"] = this.curPage;
      this.tempDataParams["pageNum"] = 2;
      getDoctorsRecommend(this.tempDataParams).then((res) => {
        self.totalPage = res.data.totalPage;
        self.dataList = res.data.dataList || [];
        console.log(">>", self.dataList);
      });
    },
    handleSwitch() {
      if (this.totalPage > this.curPage) {
        this.curPage++;
      } else {
        this.curPage = 1;
      }
      this.getDoctorsList();
    },
    handleItem(item) {
      if (this.formClient == "bjAndroid") {
        try {
          $App.getDataFormVue(item.doc_id_dt);
        } catch (e) {
          window.webkit.messageHandlers.doctor.postMessage({
            docid: item.doc_id_dt,
          });
        }
        return;
      } else if (this.formClient == "bjIOS") {
        window.webkit.messageHandlers.doctor.postMessage({
          docid: item.doc_id_dt,
        });
        return;
      } else if (this.formClient == "h5") {
        let url =
          this.FEServer +
          "doctor/details?doc_id=" +
          item.doc_id +
          "&order_source=ceping_" +
          this.tempDataParams.ceping_id;
        // window.location.replace(url);
        uni.navigateTo({
          url:
            "../doctor/details?doc_id=" +
            item.doc_id +
            "&order_source=ceping_" +
            this.tempDataParams.ceping_id,
        });
        return;
      }
      this.$nextTick(() => {
        // document.addEventListener('UniAppJSBridgeReady', function() {
        // 向 app 发送消息
        uni.postMessage({
          data: {
            action: "doctorDetails",
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=ceping_" +
              this.tempDataParams.ceping_id,
          },
        });
        if (this.tempDataParams.formClient == "wechat") {
          uni.navigateTo({
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=ceping_" +
              this.tempDataParams.ceping_id,
          });
        }
      });
      // })
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss" scoped>
.diabetes-questionSuccess {
  &-header {
    background: #fff;
    padding-top: 38px;
    padding-bottom: 48px;

    &-temp {
      .column-line {
        width: 10px;
        height: 36px;
        background: #18a2ff;
        border-radius: 0px 4px 4px 0px;
      }

      .title {
        margin-left: 20px;
        height: 36px;
        line-height: 36px;
        font-size: 38px;
        font-weight: bold;
        color: #191919;
      }
    }

    &-title {
      padding: 0 30px;
      margin-top: 60px;
      height: 29px;
      font-size: 30px;
      font-weight: bold;
      color: #191919;
    }

    &-result {
      padding: 0 34px;
      margin: 0 30px;
      margin-top: 46px;
      height: 150px;
      background: #ebf0f9;
      border-radius: 20px;
      font-size: 28px;
      font-weight: 500;
      color: #383838;
      line-height: 48px;

      span {
        color: #ee5353;
      }

      .title {
        margin-top: 22px;
      }
    }

    &-tips {
      margin-top: 54px;
      padding: 0 30px;

      .title {
        line-height: 30px;
        height: 30px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #191919;
      }

      .content {
        margin-top: 24px;
        font-size: 24px;
        font-weight: 500;
        color: #787c84;
        line-height: 36px;
      }
    }
  }

  &-section {
    margin-top: 30px;
    background: #fff;
    padding: 0 20px;
    padding-bottom: 34px;
    padding-top: 22px;

    &-header {
      margin-bottom: 10px;

      .header-title {
        padding: 20px 0;
        height: 34px;
        line-height: 34px;
        font-size: 32px;
        font-weight: bold;
        color: #20201f;
      }

      .switch-temp {
        padding: 8px 16px;
        height: 40px;
        border-radius: 10px;

        .switch-icon {
          width: 27px;
          height: 24px;
        }

        .switch-text {
          margin-left: 6px;
          height: 23px;
          line-height: 23px;
          font-size: 24px;
          font-weight: 500;
          color: #3ece80;
        }
      }
    }

    &-item {
      margin-bottom: 10px;
      background: #f7f8fa;
      border-radius: 10px;
      padding: 32px 20px;

      &-header {
        position: relative;

        .left-temp {
        
          .doctor-avatar {
            width: 100px;
            height: 100px;
            background-color: #F7F8FA;
            border-radius: 50%;
            position: relative;

            .doctor-duodian {
              position: absolute;
              bottom: -14px;
              width: 100px;
              height: 30px;
              line-height: 30px;
              background: linear-gradient(-90deg, #D09762 0%, #D6AC84 100%);
              border-radius: 15px;
              text-align: center;
              font-size: 20px;
              color: #6D3E30;
            }
          }

          .doctor-details {
            margin-left: 26px;

            .name {
              height: 32px;
              line-height: 32px;
              font-size: 34px;
              font-weight: bold;
              color: #20201f;

              .title {
                font-size: 28px;
                color: #1f1f1e;
                margin-left: 24px;
              }
            }

            .level {
              margin-top: 18px;
              height: 28px;
              line-height: 28px;
              font-size: 28px;
              font-weight: 500;
              color: #1f1f1e;

              .hos_name {
                max-width: 380px;
                margin-right: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .fac_name {}
            }
          }
        }

        .right-temp {
          position: absolute;
          right: 0px;
          top: -10px;

          .make-button {
            text-align: center;
            width: 110px;
            height: 46px;
            background: #3ece80;
            border-radius: 30px;
            font-size: 26px;
            line-height: 46px;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }

      .tags-list {
        margin-top: 20px;
        margin-left: 126px;

        .tags-item {
          // width: 58px;
          background: #3ecd80;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          padding: 0 10px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          border-radius: 4px;
          margin-right: 10px;
        }

        .tags-item:last-child {
          margin-right: 0;
        }

        .yellow {
          background-color: #ff8618;
          color: #ffffff;
        }
      }

      &-introduce {
        margin-top: 22px;
        margin-left: 126px;
        height: 72px;
        font-size: 26px;
        font-weight: 500;
        color: #808080;
        line-height: 36px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}</style>
