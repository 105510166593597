<template>
  <div class="diabetes-questionTest-content">
    <div class="diabetes-questionTest-temp">
      <div class="diabetes-questionTest-temp-header">
        {{ currentIndex + 1 }}/{{ this.dataList.length }}题
      </div>
      <div class="diabetes-questionTest-temp-context">
        {{ dataList[currentIndex].title || "" }}
      </div>
      <div class="flex-column diabetes-questionTest-temp-data">
        <el-radio-group v-model="dataList[currentIndex].answer">
          <el-radio
            :label="radioItem.value"
            v-for="(radioItem, radioIndex) in dataList[currentIndex].answerList"
            :key="radioIndex"
            >{{ radioItem.label }}</el-radio
          >
        </el-radio-group>
      </div>
    </div>
    <div
      class="flex-row-between flex-align-center diabetes-questionTest-footer"
    >
      <div class="button-previou" @click="handlePreviou()">上一题</div>
      <div class="button-next" @click="handleNext()">
        {{ currentIndex + 1 == this.dataList.length ? "提交" : "下一题" }}
      </div>
    </div>
  </div>
</template>
<script>
import questionData from "../../data/diabetesData.json";
import { Toast } from "mint-ui";
export default {
  name: "QuestionTest",
  components: { Toast },
  data() {
    return {
      radio: 3,
      currentIndex: 0,
      dataList: [{ title: "" }],
    };
  },
  mounted() {
    this.tempDataParams = this.$route.query;
    document.body.style.backgroundColor = "#EBF0F9";
    this.getList();
  },
  methods: {
    getList() {
      questionData.dateList.forEach((e) => {
        e["answer"] = "";
      });
      this.dataList = questionData.dateList || [];
    },
    //上一页操作
    handlePreviou() {
      if (this.currentIndex + 1 > 1) {
        this.currentIndex--;
      }
    },
    //下一页操作
    handleNext() {
      if (this.dataList.length > this.currentIndex + 1) {
        if (typeof this.dataList[this.currentIndex].answer == "string") {
          Toast({ message: "请选择答案", duration: 800 });
          return;
        }
        this.currentIndex++;
      } else {
         if (typeof this.dataList[this.currentIndex].answer == "string") {
          Toast({ message: "请选择答案", duration: 800 });
          return;
        }
        this.submitDate();
      }
    },
    submitDate() {
      let sumNumber = 0;
      this.dataList.forEach((item) => {
        sumNumber += item.answer;
      });
      this.tempDataParams['sum'] = sumNumber;
      console.log("测试分数：", sumNumber);
      this.$router.push({
        path: "/QuestionSuccess",
        query: this.tempDataParams,
      });
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss">

.diabetes-questionTest {
  &-content {
  }
  &-temp {
    background: #fff;
    padding: 38px 30px;
    height: 620px;
    &-header {
      height: 28px;
      line-height: 28px;
      font-size: 30px;
      font-weight: 500;
      color: #191919;
    }
    &-context {
      margin-top: 40px;
      line-height: 54px;
      font-size: 38px;
      font-weight: bold;
      color: #191919;
    }
    &-data {
      .el-radio-group {
        display: flex;
        flex-direction: column;
        .el-radio {
          margin-top: 66px;
          cursor: none !important;
        }
      }
    }
  }
  &-footer {
    margin: 70px 98px;
    .button-previou,
    .button-next {
      width: 260px;
      height: 90px;
      line-height: 90px;
      border-radius: 45px;
      text-align: center;
      font-size: 36px;
      font-weight: 500;
      cursor: pointer;
    }
    .button-previou {
      background: #ffffff;
      color: #0a0a0a;
    }
    .button-next {
      background: #18a2ff;
      color: #ffffff;
    }
  }
}
</style>
