<template>
  <div class="flex-column flex-align-center deficiencyTest-content">
    <div class="deficiencyTest-content-header">气虚体质测评</div>
    <div class="deficiencyTest-content-section">
      <div class="question-title">
        {{ currentIndex + 1 }}、{{ dataList[currentIndex].title}}
      </div>
      <div class="answer-list">
        <el-radio-group v-model="dataList[currentIndex].answer">
          <el-radio
            :label="radioItem.value"
            v-for="(radioItem, radioIndex) in dataList[currentIndex].answerList"
            :key="radioIndex"
            >{{ radioItem.label }}</el-radio
          >
        </el-radio-group>
      </div>
    </div>
    <div class="deficiencyTest-content-footer">
      <div
        class="flex-row-between flex-align-center deficiencyTest-content-footer-temp"
      >
        <div class="button-previou" @click="handlePreviou()">上一题</div>
        <div class="button-next" @click="handleNext()">
          {{ currentIndex + 1 == this.dataList.length ? "提交" : "下一题" }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import deficiencyData from "../../data/deficiencyData.json";
import { Toast } from "mint-ui";
export default {
  components: { Toast },
  data() {
    return {
      currentIndex: 0,
      dataList: [{ title: "" }],
      tempDataParams: {},
    };
  },
  mounted() {
    this.getList();
    this.tempDataParams = this.$route.query;
    document.body.style.backgroundColor = "#FFF4EA";
  },
  methods: {
    getList() {
      deficiencyData.dataList.forEach((e) => {
        e["answer"] = "";
      });
      this.dataList = deficiencyData.dataList || [];
    },
    //上一页操作
    handlePreviou() {
      if (this.currentIndex + 1 > 1) {
        this.currentIndex--;
      }
    },
    //下一页操作
    handleNext() {
      if (this.dataList.length > this.currentIndex + 1) {
        if (typeof this.dataList[this.currentIndex].answer == "string") {
          Toast({ message: "请选择答案", duration: 800 });
          return;
        }
        this.currentIndex++;
      } else {
        if (typeof this.dataList[this.currentIndex].answer == "string") {
          Toast({ message: "请选择答案", duration: 800 });
          return;
        }
        this.submitDate();
      }
    },
    submitDate() {
      let sumNumber = 0;
      this.dataList.forEach((item) => {
        sumNumber += item.answer;
      });
      console.log("测试分数：", sumNumber);
      this.tempDataParams["sum"] = sumNumber;
      this.$router.push({
        path: "/DeficiencySuccess",
        query: this.tempDataParams,
      });
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss">
.deficiencyTest-content {
  .el-radio {
    cursor: none !important;
    .el-radio__label {
      color: #5f5043 !important;
    }
  }
  .is-checked {
    .el-radio__inner {
      border-color: #a5764f !important;
      background: #a5764f !important;
    }
    .radio__inner:focus{
      background: #ffffff;
    }
  }
  .is-focus {
    border: none;
    background: none;
  }
}
</style>
<style lang="scss" scoped>
.deficiencyTest-content {
  &-header {
    margin-top: 54px;
    width: 451px;
    height: 76px;
    background: url("../../assets/deficiency/header-temp.png") no-repeat;
    background-size: 100%;
    text-align: center;
    line-height: 76px;
    font-size: 32px;
    font-family: Source Han Serif SC;
    font-weight: bold;
    color: #8b5330;
  }
  &-section {
    margin-top: 162px;
    width: 710px;
    background: url("../../assets/deficiency/temp-bg.png") no-repeat;
    background-size: 100% 100%;
    .question-title {
      padding: 44px 54px;
      padding-bottom: 0;
      font-size: 36px;
      line-height: 48px;
      font-weight: bold;
      color: #a5764f;
    }
    .answer-list {
      padding: 24px 54px;
      padding-right: 30px;
      .el-radio-group {
        display: flex;
        flex-direction: column;
        .el-radio {
          width: 100%;
          padding-top: 22px;
          padding-bottom: 22px;
          border-bottom: 1px solid #efefef;
        }
        .el-radio:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  &-footer {
    margin: 70px 0;
    margin-top: 0px;
    height: 173px;
    width: 710px;
    background: url("../../assets/deficiency/temp-shadow.png") no-repeat;
    background-size: 100% 100%;
    // padding: 94px 20px 0;
    &-temp {
      padding: 94px 84px 0;
      .button-previou,
      .button-next {
        width: 230px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 36px;
        font-weight: 500;
        // cursor: pointer;
        background: url("../../assets/deficiency/btn-bg.png") no-repeat;
        background-size: 100%;
        color: #ffffff;
      }
      .button-next {
        color: #ffffff;
      }
    }
  }
}
</style>
