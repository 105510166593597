<template>
  <div class="doctor-list-components">
    <div class="doctor-list-item" v-for="(item, index) in dataList" :key="index" @click="handleItem(item)">
      <div class="flex-row flex-align-start doctor-list-item-temp">
        <div class="doctor-avatar">
          <img class="doctor-avatar" :src="item.thumb" />
					<div class="doctor-duodian" v-if="item.is_duodian==1">多点执业</div>
				</div>
        <div class="item-temp">
          <div class="flex-row flex-align-center doctor-details">
            <div class="doctor-title">{{ item.name }}</div>
            <div class="doctor-level">{{ item.title }}</div>
          </div>
          <div class="flex-row flex-align-center hospital-details">
            <div class="hospital-name">{{ item.hos_name }}</div>
            <div class="department-name">{{ item.fac_name }}</div>
          </div>
          <div class="flex-row flex-align-center tags-list">
            <div class="tags-item" v-show="item.yibao">{{ item.yibao }}</div>
            <div class="tags-item" style="background: #50c0d4;" v-show="item.type">
              {{ item.type }}
            </div>
            <div class="tags-item" style="background: #53d0f9;" v-show="item.is_remote==1">视频门诊</div>
            <div class="tags-item" :style="{ background: item.tag_color }" v-show="item.tag">
              {{ item.tag }}
            </div>
          </div>
          <div class="doctor-introduce">{{ item.goodat }}</div>
          <div class="flex-row-between flex-align-center grade-details">
            <div class="flex-row flex-align-center make-temp">
              <div class="title">预约量</div>
              <div class="number">{{ item.booking_nums || 0 }}人</div>
            </div>
            <div class="make-button">马上预约</div>
          </div>
        </div>
      </div>
      <div class="line-row"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleItem(item) {
      this.$emit("handleItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.doctor-list {
  &-item {
    // height: 249px;
    padding: 38px 20px 0;
    background: #fff;
    &-temp {
      position: relative;
      .doctor-avatar {
					width: 100px;
					height: 100px;
					background-color: #F7F8FA;
					border-radius: 50%;
					position: relative;
					.doctor-duodian{
						position: absolute;
						bottom: -14px;
						width: 100px;
						height: 30px;
						line-height: 30px;
						background: linear-gradient(-90deg, #D09762 0%, #D6AC84 100%);
						border-radius: 15px;
						text-align: center;
						font-size: 20px;
						color: #6D3E30;
					}
				}
      .item-temp {
        width: 548px;
        margin-left: 20px;
      }
      .doctor-details {
        .doctor-title {
          height: 32px;
          line-height: 32px;
          font-size: 32px;
          font-weight: bold;
          color: #3c3e42;
        }
        .doctor-level {
          margin-left: 18px;
          height: 26px;
          line-height: 26px;
          font-size: 28px;
          font-weight: 500;
          color: #3c3e42;
        }
      }
      .hospital-details {
        margin-top: 20px;
        .hospital-name {
          height: 28px;
          font-size: 28px;
          font-weight: 500;
          color: #3c3e42;
          line-height: 30px;
        }
        .department-name {
          margin-left: 26px;
          height: 28px;
          font-size: 28px;
          font-weight: 500;
          color: #3c3e42;
          line-height: 30px;
        }
      }
      .tags-list {
        margin-top: 20px;

        .tags-item {
          // width: 58rpx;
          background: #3ecd80;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          padding: 0 10px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          border-radius: 4px;
          margin-right: 10px;
        }
        .tags-item:last-child{
          margin-right: 0;
        }
        .yellow {
          background-color: #ff8618;
          color: #ffffff;
        }
      }
      .doctor-introduce {
        margin-top: 20px;
        width: 100%;
        height: 28px;
        font-size: 28px;
        font-weight: 500;
        color: #a9a9a9;
        line-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .grade-details {
        margin-top: 22px;
        .make-temp {
          .title {
            height: 21px;
            font-size: 22px;
            font-weight: bold;
            color: #a9a9a9;
            line-height: 30px;
          }
          .number {
            height: 21px;
            margin-left: 12px;
            color: #ff0b0b;
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 30px;
          }
        }
        .make-button {
          width: 160px;
          height: 56px;
          line-height: 64px;
          color: #fff;
          text-align: center;
          background: #fdabb3;
          border-radius: 28px;
          font-size: 28px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
    .line-row {
      height: 2px;
      margin-top: 10px;
    }
  }
}
.doctor-list-components {
  .doctor-list-item:last-child {
    .line-row {
      height: 0;
      margin-top: 0;
    }
  }
}
</style>
