<<template>
  <div class="depressionTest-content">
    <div class="depressionTest-list" @scroll="onScroll">
      <div
        :id="'id_'+index"
        v-for="(item, index) in dataList"
        :key="index"
      >
      <div class="title-line"></div>
      <div class="depressionTest-list-item" :class="indexFloat==index?'null-active':''">
        <div class="title">{{ index + 1 }}、{{ item.title }}</div>
        <div class="flex-column question">
          <el-radio-group v-model="item.answer" :id="index" @change="rdionChradioChange">
            <el-radio
              :label="radioItem.value"
              v-for="(radioItem, radioIndex) in item.answerList"
              :key="radioIndex"
              >{{ radioItem.label }}</el-radio
            >
          </el-radio-group>
        </div>
        </div>
      </div>
    </div>
    <div class="flex-row-center depressionTest-footer">
      <div class="footer-button" @click="handleSubmit()">查看测试结果</div>
    </div>
    <div class="depressionTest-tips">数据来源：https://web.dtgh91.com/DepressionTest?ceping_id=100003</div>
  </div>
</template>
<script>
import depressionData from "../../data/depressionData.json";
import { Toast } from "mint-ui";
export default {
  components: { Toast },
  data() {
    return {
      dataList: [],
      indexFloat:null
    };
  },
  mounted() {
    document.body.style.backgroundColor = "#B3ADD3";
    window.addEventListener('scroll',this.onScroll,true)
    this.tempDataParams = this.$route.query;
    this.getList();
  },
  methods: {
    rdionChradioChange(e){
      this.indexFloat=null
    },
    onScroll(e){
      // console.log("ddd",e)
      let scrollItems = document.querySelectorAll(".depressionTest-list");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
          // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
          let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop;
          if (judge) {
              this.activeStep = i;
              break;
          }
      }
    },
    checkData() {
      let arr = [];
      let sum = 0;
      this.dataList.forEach((item, index) => {
        if (typeof item.answer == "string") {
          arr.push(true);
          // Toast({message:'第'+(index+1)+"道题未选答案",duration: 800})
          // return
        } else {
          sum += item.answer;
          arr.push(false);
        }
      });
      this.checkDataList = arr;
      this.sumNumber = sum;
    },
    getList() {
      depressionData.dataList.forEach((e) => {
        e["answer"] = "";
      });
      this.dataList = depressionData.dataList || [];
    },
    handleSubmit() {
      this.checkData();
      if (this.checkDataList.indexOf(true) > -1) {
        let index = this.checkDataList.indexOf(true);
        Toast({ message: "第" + (index + 1) + "道题未选答案", duration: 1200 });
        this.indexFloat = index
        // document.documentElement.scrollTop=166*(index)
        document.querySelector('#id_'+index).scrollIntoView({
          behavior: "smooth"
        });
        return;
      } else {
        console.log(">分数", this.sumNumber);
        this.tempDataParams["sum"] = this.sumNumber;
        this.$router.push({
          path: "/DepressionSuccess",
          query: this.tempDataParams,
        });
      }
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss" scoped>
.depressionTest {
  &-content {
    padding: 0 30px;
  }
  &-list {
    margin-top: 30px;
    // overflow: scroll;
     .title-line{
      height: 20px;
    }
    .null-active{
      box-shadow:0px 0px 8px #00e1ff;
      // box-shadow: 50px 50px 5px #87CEFA 2px #87CEFA outset;
    }
    &-item {
      background: #fff;
      padding: 30px 36px;
   
      .title {
        line-height: 48px;
        font-size: 30px;
        font-weight: bold;
        color: #7b4ab8;
      }
      .question {
        .el-radio-group {
          display: flex;
          flex-direction: column;
          .el-radio {
            margin-top: 56px;
            cursor: none !important;
          }
        }
      }
    }
  }
  &-footer {
    height: 120px;
    margin-top: 16px;
    .footer-button {
      width: 260px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      background: #572aea;
      border-radius: 35px;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  &-tips{
    font-size: 24px;
    padding-bottom:30px ;
    color: #4d4c4c;
  }
}
</style>
