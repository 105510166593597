<template>
  <div class="doctor-list-components">
    <div
      class="doctor-list-item"
      v-for="(item, index) in dataList"
      :key="index"
      @click="handleItem(item)"
    >
      <div class="flex-row flex-align-start doctor-list-item-temp">
        <img class="doctor-avatar" :src="item.thumb" />
        <div class="flex-row flex-align-center grade-details">
           <img
                class="grade-details-icon"
                width="100%"
                src="../../assets/ranking/zan@3x.png"
            />
           
            <div class="grade-details-number">{{item.likes ||0}}</div>
        </div>
        <div class="item-temp">
          <div class="flex-row flex-align-center doctor-details">
            <div class="doctor-title">{{ item.name }}</div>
            <div class="doctor-level">{{ item.title }}</div>
          </div>
          <div class="flex-row flex-align-center hospital-details">
            <div class="hospital-name">{{ item.hos_name }}</div>
            <div class="duodian" v-if="item.is_duodian==1">多点执业</div>
          </div>
          <div class="line-row"></div>
          <div class="doctor-introduce-temp">
              <div class="doctor-introduce-temp-title">医生擅长</div>
              <img class="doctor-introduce-temp-icon" width="100%" src="../../assets/ranking/K@3x.png"/>
          </div>
          <div class="doctor-introduce">{{ item.goodat }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleItem(item) {
      this.$emit("handleItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.doctor-list {
  &-item {
      // height: 249px;
    padding: 30px 20px 0;
    margin-left: 30px;
    background: #fff;
    height: 336px;
    position: relative;
    margin-top: 50px;
    border-radius: 20px;
    &-temp {
      .doctor-avatar {
        position: absolute;
        top: -30px;
        left: -24px;
        width: 116px;
        height: 120px;
        border-radius: 16px;
         border:2px solid #f0f8fc;
      }
      .grade-details{
          position: absolute;
          right: 20px;
          top: 10px;
          .grade-details-icon{
              width: 66px;
              height: 66px;
          }
          .grade-details-number{
            font-size: 26px;
            font-weight: 500;
            color: #FD8636;
            line-height: 40px;
          }
      }
      .item-temp {
        width: 548px;
        margin-left: 110px;
      }
      .doctor-details {
        .doctor-title {
          height: 36px;
          line-height: 36px;
          font-size: 36px;
          font-weight: bold;
          color: #1F1F1F;
        }
        .doctor-level {
          margin-left: 18px;
          height: 30px;
          line-height: 30px;
          font-size: 30px;
          font-weight: 500;
          color: #1F1F1F;
        }
      }
      .hospital-details {
        margin-top: 28px;
        .hospital-name {
          height: 30px;
          font-size: 30px;
          font-weight: 500;
          color: #1F1F1F;
          line-height: 30px;
        }
        .duodian{
            margin-left: 28px;
            padding: 6px 10px;
            background: #CBE5FF;
            height: 24px;
            line-height: 24px;
            border-radius: 6px;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #378BFF;
        }
      }
      .doctor-introduce {
        margin-top: 20px;
        width: 100%;
        height: 118px;
        font-size: 28px;
        font-weight: 500;
        color: #a9a9a9;
        line-height: 40px;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .doctor-introduce-temp{
          margin-top: 14px;
          position: relative;
          .doctor-introduce-temp-title{
            height: 30px;
            font-size: 30px;
            font-weight: bold;
            color: #1F1F1F;
            line-height: 40px;
          }
          .doctor-introduce-temp-icon{
              position:absolute;
              left: -34px;
              top: 6px;
              width: 20px;
              height: 15px;
          }
      }
    }
    .line-row {
      height: 2px;
      margin-top: 24px;
    }
  }
}

</style>
