<template>
  <div class="flex-column flex-align-center breast-introduce-content">
    <div class="breast-introduce-content-temp">
      <div class="breast-introduce-content-temp-list">
        <div class="flex-column flex-align-center breast-introduce-content-temp-list-item">
          <div class="header-tips-img">
            <img width="100%" src="@/assets/breast/jiankang@3x.png" />
          </div>
          <div class="header-tips-content">
            <div class="content">
              若乳房出现异常可能是以下疾病，具体情况应及时到医院进一步诊治，当然未发现异常也应定期到医院体检，做到早发现早治疗。
            </div>
            <div class="section-handle-list">
              <div class="section-handle-list-item" v-for="(item, index) in itemList" :key="index">
                <b>{{ item.title }}</b>
                <div class="content" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="breast-introduce-content-temp-doctor" v-if="dataList.length > 0 && loaddingVisible">
        <div class="flex-row-center flex-align-center doctor-tremp-header">
          <div class="flex-row-center flex-align-center doctor-tremp-header-img">
            <img width="100%" height="100%" src="@/assets/breast/tuijian@3x.png" />
          </div>
        </div>
        <doctorList :dataList="dataList" @handleItem="handleItem" />
      </div>
      <div class="flex-row-center breast-introduce-content-footer" v-if="dataList.length > 0 && loaddingVisible">
        <div class="footer-button" @click="handleSwitch()">换一换医生</div>
      </div>
      <div class="breast-introduce-content-null" v-if="dataList.length == 0 && loaddingVisible"></div>
    </div>
    <clickLoading ref="showToast"></clickLoading>
  </div>
</template>
<script>
import doctorList from "../../components/DoctorList.vue";
import { getDoctorsRecommend } from "../../api/doctorApi.js";
import clickLoading from '../../components/modal/showToast.vue'
export default {
  name: "BreastIntroduce",
  components: { doctorList, clickLoading },
  data() {
    return {
      itemList: [
        {
          title: "1.乳腺肿块：",
          content:
            "乳腺肿块是乳腺肿瘤的主要症状，乳腺肿块也见于乳腺增生症、乳腺结核等；",
        },
        {
          title: "2.乳腺腺体局限性增厚：",
          content:
            "触摸乳腺时发现比其周围稍厚的组织，界限不清，难以测出其确切大小。此种情况在未闭经的妇女尤其随月经周期有些大小的变化，多属生理性。但增厚组织长期存在，与月经周期变化无关，或日益增厚及范围增大，尤其出现在绝经后妇女时，必须予以重视；",
        },
        {
          title: "3.乳腺疼痛：",
          content:
            "乳腺的剧烈疼痛伴有触痛常为乳腺的炎症性表现，见于急性乳腺炎和乳腺脓肿。如乳房局部疼痛，常与月经周期有关，一般多见于乳腺的单纯性和囊性增生；",
        },
        {
          title: "4.乳头溢液：：",
          content:
            "在妇女非哺乳期间，发生乳头溢液多属病理性，最常见的病因是导管内乳头状瘤。其次为乳腺囊性增生和乳管扩张症，约15%的病人为乳腺癌，应特别注意血性溢液并伴有乳房肿块的情况。<br/>有些药物也可引起双侧乳头溢液，如雌激素、避孕药等；",
        },
        {
          title: "5.乳腺皮肤改变：",
          content:
            "多数乳腺疾病其乳腺皮肤无任何改变，急性乳腺炎常有皮肤红肿，乳腺结核可伴有皮肤溃疡或瘘管，乳腺癌可出现皮肤“橘皮”样改变。有一种特殊类型的乳癌-炎性乳癌，乳腺皮肤可出现广泛红肿；",
        },
        {
          title: "6.乳房轮廓改变：",
          content:
            "正常乳房具有完整的弧形轮廓，此种弧形轮廓出现任何缺陷或异常，均非常重要，常为乳腺癌的早期表现；",
        },
        {
          title: "7.乳头改变：",
          content:
            "乳腺先天性发育不全时乳头可以内陷，多见于无哺乳史的妇女。乳头内陷也见于乳腺癌，常为单侧内陷；",
        },
        {
          title: "8.乳头周围皮肤反复出现湿疹、皮肤瘙痒，",
          content: "经久不愈，应考虑派杰氏病的可能，为一种特殊类型的乳腺癌。",
        },
      ],
      tempDataParams: {},
      curPage: 1,
      dataList: [],
      totalPage: 1,
      loaddingVisible: false,
      formClient: "",

    };
  },
  mounted() {
    this.formClient = this.$route.query.formClient || "";
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () { });
    });
    this.tempDataParams = this.$route.query;
    this.getDoctorsList();
  },
  methods: {
    handleItem(item) {
      if (this.formClient == "bjAndroid") {
        //  window.webkit.messageHandlers.doctor.postMessage({docid: item.doc_id});
        try {
          $App.getDataFormVue(item.doc_id_dt);
        } catch (e) {
          window.webkit.messageHandlers.doctor.postMessage({
            docid: item.doc_id_dt,
          });
        }
        return;
      } else if (this.formClient == "bjIOS") {
        window.webkit.messageHandlers.doctor.postMessage({
          docid: item.doc_id_dt,
        });
        return;
      } else if (this.formClient == "h5") {
        let url =
          this.FEServer +
          "doctor/details?doc_id=" +
          item.doc_id +
          "&order_source=ceping_" +
          this.tempDataParams.ceping_id;
        // window.location.replace(url)
        uni.navigateTo({
          url:
            "../doctor/details?doc_id=" +
            item.doc_id +
            "&order_source=ceping_" +
            this.tempDataParams.ceping_id,
        });
        return;
      }
      this.$nextTick(() => {
        // document.addEventListener('UniAppJSBridgeReady', function() {
        // 向 app 发送消息
        uni.postMessage({
          data: {
            action: "doctorDetails",
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=ceping_" +
              this.tempDataParams.ceping_id,
          },
        });
        if (this.tempDataParams.formClient == "wechat") {
          // uni.navigateBack()
          uni.navigateTo({
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=ceping_" +
              this.tempDataParams.ceping_id,
          });
        }
      });
      // })
    },
    getDoctorsList() {
      const self = this;
      this.tempDataParams["page"] = this.curPage;
      this.tempDataParams["pageNum"] = 3;
      getDoctorsRecommend(this.tempDataParams).then((res) => {
        self.totalPage = res.data.totalPage;
        self.dataList = res.data.dataList || [];
        self.loaddingVisible = true;
        console.log(">>", self.dataList);
      });
    },
    handleSwitch() {
      if (this.totalPage == 1) {
        this.$refs.showToast.confirm()
        return
      }
      if (this.totalPage > this.curPage) {
        this.curPage++;
      } else {
        this.curPage = 1;
      }
      this.getDoctorsList();
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss" scoped>
.breast-introduce-content {
  background: #ffe4e1;
  padding: 0 20px;

  &-temp {
    width: 100%;

    &-list {
      width: 100%;

      &-item {
        margin-top: 84px;
        width: 100%;
        position: relative;

        .header-tips-img {
          position: absolute;
          top: -28px;
          width: 440px;
          height: 80px;
        }

        .header-tips-content {
          background: #fff;
          padding: 38px 34px;
          padding-top: 88px;
          font-size: 28px;
          color: #374d9b;
          line-height: 50px;
          border-radius: 40px;

          b {
            background-image: linear-gradient(#fff 60%, #f3dadd 30%);
          }
        }
      }
    }

    &-doctor {
      margin-top: 40px;
      background: #fff;
      padding: 24px 0 42px;
      padding-top: 0;
      margin-bottom: 54px;
      border-radius: 40px;

      .doctor-tremp-header {
        width: 100%;
        height: 90px;
        background: #fdabb3;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;

        .doctor-tremp-header-img {
          width: 242px;
          height: 31px;
        }
      }
    }
  }

  &-null {
    height: 40px;
  }

  &-footer {
    height: 232px;

    .footer-button {
      margin: 44px;
      width: 360px;
      height: 80px;
      line-height: 80px;
      background: #fdabb3;
      border-radius: 45px;
      font-size: 30px;
      font-family: TsangerYuMo;
      font-weight: bold;
      color: #fff;
      text-align: center;
      cursor: wait;
    }
  }
}</style>
