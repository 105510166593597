<template>
  <div class="ranking-recommend-content">
    <div class="ranking-recommend-header">
      <img
        class="ranking-recommend-header-img"
        width="100%"
        :src="dataInfo.rank_pic?dataInfo.rank_pic:require('../../assets/ranking/top_recommend@3x.png')"
      />
    </div>
    <div class="ranking-recommend-doctor">
        <recommend-doctor-list class="ranking-recommend-doctor-list" @handleItem="handleItem" :dataList="dataList" />
         <div style="height:22px"></div>
    </div>
  </div>
</template>
<script>
import { getRankingDoctorList } from "../../api/rankingApi.js";
import RecommendDoctorList from '../../components/ranking/RecommendDoctorList.vue'
export default {
    components:{RecommendDoctorList},
    data() {
        return {
            tempDataParams:{},
            formClient:'',
            dataList:[],
            dataInfo:{
              rank_pic:''
            }
        }
    },
      mounted() {
        this.tempDataParams = this.$route.query;
        this.formClient = this.$route.query.formClient || '';
        document.body.style.backgroundColor = "#F6F7F9";
        this.getDoctorsList();
        this.$nextTick(() => {
          document.addEventListener("UniAppJSBridgeReady", function() {
          });
        });
    },
    methods:{
         getDoctorsList() {
            const self = this;
            this.tempDataParams["page"] = this.curPage;
            this.tempDataParams["pageNum"] = 10;
            getRankingDoctorList(this.tempDataParams).then((res) => {
                self.dataList = res.data.dataList || [];
                self.dataInfo = res.data.dataInfo || {rank_pic:""};
                document.title = self.dataInfo.title;
                // self.dataInfo = res.data.dataInfo ||'../../assets/ranking/top_recommend@3x.png'
                document.title = self.dataInfo.title;
            });
        },
        handleItem(item) {
       if(this.formClient=='bjAndroid'){
        try {
           $App.getDataFormVue(item.doc_id_dt);
        } catch (e) {
            window.webkit.messageHandlers.doctor.postMessage({docid: item.doc_id_dt});
        }
         return
      }else if(this.formClient=='bjIOS'){
         window.webkit.messageHandlers.doctor.postMessage({docid: item.doc_id_dt});
         return
      }else if(this.formClient == "h5"){
         let url = this.FEServer+"doctor/details?doc_id="+item.doc_id +"&order_source=rank_" + this.tempDataParams.rank_id;
          // window.location.replace(url) 
          uni.navigateTo({
            url:
              "../doctor/details?doc_id="+item.doc_id +"&order_source=rank_" + this.tempDataParams.rank_id
          });
          return
      }
      this.$nextTick(() => {
        // document.addEventListener('UniAppJSBridgeReady', function() {
        // 向 app 发送消息
        uni.postMessage({
          data: {
            action: "doctorDetails",
            url: "../doctor/details?doc_id=" + item.doc_id+"&order_source=rank_" +
              this.tempDataParams.rank_id,
          },
        });
        if(this.tempDataParams.formClient == 'wechat'){
          uni.navigateTo({
						url:"../doctor/details?doc_id=" + item.doc_id+"&order_source=rank_" +
              this.tempDataParams.rank_id,
					})
        }
      });
      // })
    },
    },
    beforeCreate() {
        document.title = this.$route.meta.title;
    },
}
</script>
<style lang="scss" scoped>
.ranking-recommend {
  &-header {
    &-img {
      width: 100%;
      height: 840px;
    }
  }
  &-doctor{
      &-list{
          margin: 0 20px;
      }
  }
}
</style>
