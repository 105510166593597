<template>
  <div class="doctor-list-components">
    <div
      class="doctor-list-item" :class="index+1==dataList.length?'last-item':''"
      v-for="(item, index) in dataList"
      :key="index"
      @click="handleItem(item)"
    >
      <div class="flex-row flex-align-start doctor-list-item-temp">
        <div class="flex-row-center flex-align-center doctor-ranking">
          <img class="ranking-icon" v-if="index==0" src="../../assets/ranking/one@3x.png" width="100%" />
          <img class="ranking-icon" v-if="index==1" src="../../assets/ranking/two@3x.png" width="100%" />
          <img class="ranking-icon" v-if="index==2" src="../../assets/ranking/three@3x.png" width="100%" />
          <div class="ranking-number" v-if="index>=3">{{index+1<10?'0'+(index+1):index+1}}</div>
        </div>
        <img class="doctor-avatar" :src="item.thumb" />
        <div class="item-temp">
          <div class="flex-row-between  flex-align-center doctor-details">
            <div class="flex-row flex-align-center">
              <div class="doctor-title">{{ item.name }}</div>
              <div class="doctor-level">{{ item.title }}</div>
            </div>
            <div class="flex-row flex-align-center make-temp">
              <div class="title">预约量</div>
              <div class="number">{{ item.booking_nums || 0 }}</div>
            </div>
          </div>
          <div class="flex-row flex-align-center hospital-details">
            <div class="hospital-name">{{ item.hos_name }}</div>
            <div class="department-name" v-if="item.is_duodian==1">多点执业</div>
          </div>
          <!-- <div class="flex-row flex-align-center tags-list">
            <div class="tags-item" v-show="item.yibao">{{ item.yibao }}</div>
            <div class="tags-item" style="background: #50c0d4;" v-show="item.type">{{ item.type }}</div>
            <div class="tags-item" :style="{ background: item.tag_color }" v-show="item.tag">{{ item.tag }} </div>
          </div> -->
        </div>
      </div>
      <div class="flex-row flex-row-start doctor-introduce">
        <div class="introduce-title">擅长：</div>
        <div class="introduce-context">{{ item.goodat }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleItem(item) {
      this.$emit("handleItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.doctor-list {
  &-components{
    .last-item{
      border-radius: 0 0 20px 20px;
    }
  }
  &-item {
    // height: 249px;
    padding: 38px 18px 0;
    height: 270px;
    &-temp {
      .doctor-ranking{
        width: 46px;
        height: 55px;
        margin-right: 20px;
        .ranking-icon{
          width: 46px;
          height: 55px;
        }
        .ranking-number{
          height: 55px;
          line-height: 55px;
          font-size: 30px;
          font-weight: bold;
          color: #418FED;
        }
      }
      .doctor-avatar {
        width: 116px;
        height: 120px;
        border-radius: 16px;
        border:2px solid #f0f8fc;
      }
      .item-temp {
        width: 474px;
        margin-left: 20px;
      }
      .doctor-details {
         margin-top: 4px;
        .doctor-title {
          height: 32px;
          line-height: 32px;
          font-size: 32px;
          font-weight: bold;
          color: #1F1F1F;
        }
        .doctor-level {
          margin-left: 18px;
          height: 26px;
          line-height: 26px;
          font-size: 28px;
          font-weight: 500;
          color: #1F1F1F;
        }
         .make-temp {
          .title {
            height: 22px;
            font-size: 22px;
            font-weight: 400;
            color: #1F1F1F;
            line-height: 30px;
          }
          .number {
            height: 22px;
            margin-left: 2px;
            color: #FF4547;
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: 400; 
            line-height: 30px;
          }
        }
      }
      .hospital-details {
        margin-top: 40px;
        .hospital-name {
          max-width: 262px;
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
          padding: 8px 20px;
          height: 28px;
          background: linear-gradient(-90deg, #9BC2FA 0%, #387FEB 100%);
          border-radius: 22px;
          font-size: 28px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 28px;
        }
        .department-name {
          text-align: center;
          width: 120px;
          margin-left: 16px;
          height: 24px;
          line-height: 24px;
          background: linear-gradient(-90deg, #99C0F9 0%, #3980EB 100%);
          border-radius: 22px;
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
          padding: 10px 14px;
        }
      }
      .tags-list {
        margin-top: 20px;

        .tags-item {
          // width: 58rpx;
          background: #3ecd80;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          padding: 0 10px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          border-radius: 4px;
          margin-right: 10px;
        }

        .yellow {
          background-color: #ff8618;
          color: #ffffff;
        }
      }
     
    }
    .doctor-introduce {
        margin-top: 28px;
        width: 100%;
       
        .introduce-title{
          margin-left: 60px;
          width: 90px;
          font-size: 28px;
          font-weight: 500;
          color: #3980EB;
        }
        .introduce-context{
          width: 522px;
          height: 80px;
          font-size: 28px;
          font-weight: 400;
          color: #5F6875;
          line-height: 40px;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
  }
  
  &-item:nth-child(odd){
    background: #ffffff;
  }
  &-item:nth-child(even){
    background: #EEF4FD;
  }
  &-item:first-child{
    border-radius: 20px 20px 0 0;
  }
}
</style>
