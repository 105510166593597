import request from '../api/request'

//验证token是否失效
export function getStatus(params) {
  return request({
    url: 'user/getStatus',
    method: 'post',
    params
  })
}
  
