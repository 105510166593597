import request from '../api/request'

//获取短信验证码
export function sendSmsData(data) {
  return request({
    baseURL:'https://api.sms.dtgh91.com/',
    url: 'sms/send/',
    method: 'post',
    data:data
  })
}
//提交医生招募接口
export function doctorReg(data) {
  return request({
    baseURL:'https://api.ihospital.dtgh91.com/',
    url: 'data/doctorReg',
    method: 'post',
    data:data
  })
}
//隐私协议
export function getText(data) {
  return request({
    baseURL:'https://api.ihospital.dtgh91.com/',
    url: 'data/getText',
    method: 'post',
    data:data
  })
}
