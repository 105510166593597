<template>
  <div class="recruit-step1-content">
    <div class="recruit-step1-temp">
      <div class="header-tips">请填写真实有效的信息，平台将保护您的隐私</div>
      <div class="flex-row-between flex-align-center header-proces">
        <div class="flex-column flex-align-center header-proces-item active-item">
          <div class="item-number">01</div>
          <div class="item-text">基础信息</div>
        </div>
        <div class="line-dashed"></div>
        <div class="flex-column flex-align-center header-proces-item">
          <div class="item-number">02</div>
          <div class="item-text">资质认证</div>
        </div>
        <div class="line-dashed"></div>
        <div class="flex-column flex-align-center header-proces-item">
          <div class="item-number">03</div>
          <div class="item-text">完成</div>
        </div>
      </div>
      <div class="temp-info">
        <div class="flex-row header-custom">个人信息<span>*</span></div>
        <div class="header-introduction">请上传有效证件照片，确保证件边框完整，字体清晰</div>
        <div class="flex-row-between flex-align-center temp-info-card">
          <div class="temp-info-card-item">
            <imageUpload :styleType="'IDCardA'" @changeFile="changeFile" :dataValue="tempData.IDCardA" />
          </div>
          <div class="temp-info-card-item">
            <imageUpload :styleType="'IDCardB'" @changeFile="changeFile" :dataValue="tempData.IDCardB" />
          </div>
        </div>
        <div class="temp-info-from">
          <div class="flex-row-between flex-align-center temp-info-from-item">
            <span class="title">姓名</span>
            <input class="input" v-model="tempData.name" placeholder="请输入" />
          </div>
          <div class="line-row"></div>
          <div class="flex-row-between flex-align-center temp-info-from-item">
            <span class="title">身份证号</span>
            <input class="input" v-model="tempData.IDCard" placeholder="请输入" />
          </div>
          <div class="line-row"></div>
          <div class="flex-row-between flex-align-center temp-info-from-item">
            <span class="title">联系方式</span>
            <input class="input" v-model="tempData.mobile" placeholder="请输入" />
          </div>
        </div>
      </div>
      <div class="avatar-info">
        <div class="flex-row header-custom">上传头像<span>*</span></div>
        <div class="header-introduction">请上传彩色真实清晰的照片</div>
        <div class="avatar-info-img">
          <imageUpload @changeFile="changeFile" :styleType="'doctorThumb'" :dataValue="tempData.doctorThumb" />
        </div>
      </div>
      <div class="from-info">
        <div class="flex-row header-custom">其他信息<span>*</span></div>
        <div class="from-info-list">
          <div class="from-info-list-item">
            <div class="item-title">擅长疾病</div>
            <textarea class="item-textarea" v-model="tempData.doctorGoodat" placeholder="请输入"></textarea>
          </div>
          <div class="from-info-list-item">
            <div class="item-title">个人简介</div>
            <textarea class="item-textarea" v-model="tempData.doctorIntroduction" placeholder="请输入"></textarea>
          </div>
        </div>
      </div>
      <div class="recruit-step1-height"></div>
    </div>
    <div class="flex-column flex-align-center recruit-step1-footer">
      <div class="flex-row flex-align-center footer-checkbox">
        <el-checkbox v-model="tempData.checked"><span style="color: #1f1f1f">我已阅读并接受</span></el-checkbox>
        <div><a target="_blank" @click="popupVisible=true,dataType=2">《服务协议》</a>及<a target="_blank" @click="popupVisible=true,dataType=1">《隐私政策》</a></div>
      </div>
      <div class="footer-button" @click="handleSubmit()">保存/下一步</div>
    </div>
    <popup v-model="popupVisible" position="bottom">
      <div slot="title" class="popup-title">{{ dataType=='1'?'隐私保护政策':'医院用户服务协议' }}</div>
      <div slot="content" class="popup-context">
        <div v-if="dataType==1" v-html="dadaText.privacy"></div>
        <div v-if="dataType==2" v-html="dadaText.agreement"></div>
      </div>
    </popup>
  </div>
</template>
<script>
import imageUpload from "../../components/ImageUpload/index";
import popup from '../../components/modal/popup'
import { doctorReg,getText } from "../../api/recruitApi.js";
import { Toast } from "mint-ui";
import { getDoctorRegData, setDoctorRegData, removeDoctorRegData } from "../../store/cookie";
import axios from 'axios'
export default {
  components: { imageUpload, Toast,popup },
  data() {
    return {
      tempData: {
        checked: true,
        doctorIntroduction: "",
        doctorGoodat: "",
        doctorThumb: "",
        IDCard: "",
        mobile: "",
        name: "",
        IDCardA: "",
        IDCardB: "",
      },
      popupVisible:false,
      dadaText:{
        agreement:'',
        privacy:'',
      },
      dataType:1
    };
  },
  created() {
    this.getTextData()
  },
  methods: {
    getTextData(){
      getText().then(res=>{
        this.dadaText = res.data
      })
    },
    checkData() {
      const self = this;
      if (!self.tempData.IDCardA || !self.tempData.IDCardB) {
        Toast({
          message: "请上传身份证正反面",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.name) {
        Toast({
          message: "请输入姓名",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.IDCard) {
        Toast({
          message: "请输入身份证号",
          duration: 1200,
        });
        return false;
      }
      /*if (/^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/.test(self.tempData.IDCard) == false) {
        Toast({
          message: "身份证号格式错误",
          duration: 1200,
        });
        return false;
      }*/
      if (!this.isIdCard(self.tempData.IDCard)) {
        Toast({
          message: "身份证号格式错误",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.mobile) {
        Toast({
          message: "请输入手机号",
          duration: 1200,
        });
        return false;
      }

      if (!/^((1[3-9][0-9]))\d{8}$/.test(self.tempData.mobile)) {
        Toast({
          message: "请输入正确的手机号码",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.doctorThumb) {
        Toast({
          message: "请上传头像",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.doctorGoodat) {
        Toast({
          message: "请输入擅长疾病",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.doctorIntroduction) {
        Toast({
          message: "请输入个人简介",
          duration: 1200,
        });
        return false;
      }
      return true;
    },
    isIdCard(idCard) {
      // 15位和18位身份证号码的正则表达式
      var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

      // 如果通过该验证，说明身份证格式正确，但准确性还需计算
      if (regIdCard.test(idCard)) {
        if (idCard.length == 18) {
          var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); // 将前17位加权因子保存在数组里
          var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); // 这是除以11后，可能产生的11位余数、验证码，也保存成数组
          var idCardWiSum = 0; // 用来保存前17位各自乖以加权因子后的总和
          for (var i = 0; i < 17; i++) {
            idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
          }

          var idCardMod = idCardWiSum % 11; // 计算出校验码所在数组的位置
          var idCardLast = idCard.substring(17); // 得到最后一位身份证号码

          // 如果等于2，则说明校验码是10，身份证号码最后一位应该是X
          if (idCardMod == 2) {
            console.log("d1");
            if (idCardLast == "X" || idCardLast == "x") {
              //alert("恭喜通过验证啦！");
              console.log("d2");

              return true;
            } else {
              console.log("d3");

              //alert("身份证号码错误！");
              return false;
            }
          } else {
            // 用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
            if (idCardLast == idCardY[idCardMod]) {
              console.log("d4");

              //alert("恭喜通过验证啦！");
              return true;
            } else {
              console.log("d5");

              //alert("身份证号码错误！");
              return false;
            }
          }
        } else {
          return true;
        }
      } else {
        console.log("d0");

        //alert("身份证格式不正确!");
        return false;
      }
    },
    handleSubmit() {
      if (!this.checkData()) {
        return;
      }
      if (!this.tempData.checked) {
        Toast({
          message: "请仔细阅读并勾选相关协议与政策",
          duration: 1200,
        });
        return false;
      }
      this.tempData["type"] = 2;
      setDoctorRegData(JSON.stringify(this.tempData));
      this.$router.push({ path: "/recruitStep2" });
    },
    changeFile(url, type) {
      console.log(">>url, type>", url, type);
      if (type == "IDCardA") {
        this.tempData.IDCardA = url;
      } else if (type == "IDCardB") {
        this.tempData.IDCardB = url;
      } else if (type == "doctorThumb") {
        this.tempData.doctorThumb = url;
      }
      console.log(">>>>", this.tempData);
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
    removeDoctorRegData();
  },
};
</script>
<style lang="scss">
.recruit-step1 {
  &-temp {
    padding: 0 20px;
    background-color: #f6f8fa;
    .header-custom {
      font-weight: 500;
      font-size: 36px;
      color: #101010;
      span {
        margin-top: 6px;
        margin-left: 16px;
        width: 17px;
        height: 17px;
        font-weight: bold;
        font-size: 36px;
        color: #f58040;
        line-height: 40px;
      }
    }
    .header-introduction {
      margin-top: 22px;
      font-weight: 500;
      font-size: 26px;
      color: #f58040;
    }
    .header-tips {
      padding: 0 10px;
      height: 80px;
      line-height: 80px;
      background: #f6f8fa;
      font-weight: 500;
      font-size: 28px;
      color: #666666;
    }
    .header-proces {
      background-color: #fff;
      height: 160px;
      border-radius: 16px;
      .header-proces-item {
        width: 120px;
        margin: 0 22px;
        .item-number {
          width: 50px;
          height: 50px;
          line-height: 50px;
          border-radius: 50%;
          font-size: 28px;
          background-color: #f3f5f9;
          color: #999999;
          text-align: center;
        }
        .item-text {
          margin-top: 20px;
          height: 28px;
          line-height: 28px;
          font-weight: 500;
          font-size: 28px;
          color: #101010;
        }
      }
      .line-dashed {
        width: 80px;
        margin-bottom: 40px;
        border: 1px dashed #d1d1d1;
      }
      .active-item {
        .item-number {
          background: #ffe4d6;
          color: #f58040;
        }
      }
    }
    .temp-info {
      margin-top: 20px;
      background: #ffffff;
      border-radius: 16px;
      padding: 40px 30px 10px;
      .temp-info-card {
        margin-top: 32px;
        .temp-info-card-item {
        }
      }
      .temp-info-from {
        margin-top: 30px;
        .line-row {
          width: 100%;
          height: 2px;
          background: #e8e8e8;
        }
        .temp-info-from-item {
          .title {
            width: 162px;
            height: 110px;
            line-height: 110px;
            font-weight: bold;
            font-size: 32px;
            color: #000000;
          }
          .input {
            width: 488px;
            height: 110px;
            line-height: 110px;
            font-weight: 500;
            font-size: 28px;
            color: #999999;
            border: none;
            outline: none;
          }
        }
      }
    }
    .avatar-info {
      margin-top: 20px;
      background: #ffffff;
      border-radius: 16px;
      padding: 40px 30px;
      .avatar-info-img {
        margin-top: 34px;
      }
    }
    .from-info {
      margin-top: 20px;
      background: #ffffff;
      border-radius: 16px;
      padding: 40px 30px 30px;
      .from-info-list {
        margin-top: 12px;
        .from-info-list-item {
          margin-top: 40px;
          width: 100%;
          .item-title {
            font-weight: bold;
            font-size: 32px;
            color: #000000;
          }
          .item-textarea {
            margin-top: 30px;
            height: 140px;
            width: 610px;
            background: #f3f5f9;
            border-radius: 12px;
            padding: 30px 20px;
            font-weight: 400;
            font-size: 28px;
            color: #1f1f1f;
            border: none;
            outline: none;
          }
        }
      }
    }
  }
  &-height {
    height: 240px;
  }
  &-footer {
    background: #ffffff;
    position: fixed;
    bottom: 0;
    height: 220px;
    width: 100%;
    .footer-checkbox {
      padding-left: 80px;
      margin-top: 24px;
      font-weight: 500;
      font-size: 24px;
      color: #1f1f1f;
      width: 100%;
      .el-checkbox .el-checkbox__label span {
        font-size: 24px !important;
      }
      a {
        font-size: 24px;
        color: #1b81ff;
        text-decoration: none;
      }
    }
    .footer-button {
      margin-top: 22px;
      width: 690px;
      height: 80px;
      line-height: 80px;
      background: #3c81f3;
      border-radius: 40px;
      font-weight: 500;
      font-size: 36px;
      color: #ffffff;
      text-align: center;
    }
  }
}
</style>
