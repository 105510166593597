<template>
  <div class="no-video-content">
    <div class="no-video-content-temp">
      <div class="flex-row-center no-video-content-temp-header">
        <img width="100%" src="@/assets/server/video-image@3x.png" />
      </div>
      <div class="flex-column flex-align-center no-video-content-temp-section">
        <div
          class="flex-column flex-align-center no-video-content-temp-section-item"
        >
          <div class="item-title">远程视频门诊</div>
          <img class="item-line" src="@/assets/server/video-l@3x.png" />
          <div class="tips-list">
            <div class="flex-row flex-align-start tips-list-item">
              <img class="safe-icon" src="@/assets/server/video-k@3x.png" />
              <div class="safe-text">节省就医时间，预约时间随地视频问诊</div>
            </div>
            <div class="flex-row flex-align-start tips-list-item">
              <img class="safe-icon" src="@/assets/server/video-k@3x.png" />
              <div class="safe-text">避免医院接触人群，减少非必要感染风险</div>
            </div>
            <div class="flex-row flex-align-start tips-list-item">
              <img class="safe-icon" src="@/assets/server/video-k@3x.png" />
              <div class="safe-text">解决行动不便者困扰，在家完成复诊开药</div>
            </div>
          </div>
        </div>
        <div class="no-video-content-temp-section-item1">
          <div class="height-line"></div>
          <div class="tips-list">
            <div class="flex-row flex-align-center tips-list-item">
              <div class="item-title">出诊方式：</div>
              <div class="item-value">视频（医生本人）</div>
            </div>
            <div class="flex-row flex-align-center tips-list-item">
              <div class="item-title">出诊时间：</div>
              <div class="item-value">预约制（预约后确定具体时间）</div>
            </div>
            <div class="flex-row flex-align-center tips-list-item">
              <div class="item-title">出诊时长：</div>
              <div class="item-value">约10分钟/次（病情复杂可延时）</div>
            </div>
          </div>
        </div>
        <div
          class="flex-column flex-align-center no-video-content-temp-section-item"
          style="margin-top: 20px;"
        >
          <div class="item-title">温馨提示</div>
          <img class="item-line" src="@/assets/server/video-l@3x.png" />
          <div class="tips-list">
            <div class="flex-row flex-align-start tips-list-item">
              <div class="safe-radio"></div>
              <div class="safe-text1">
                远程视频问诊服务由所预约医院提供，本平台仅提供预约服务。
              </div>
            </div>
            <div class="flex-row flex-align-start tips-list-item">
              <div class="safe-radio"></div>
              <div class="safe-text1">
                远程视频问诊针对复诊患者，需提前准备好各项检查、诊断结果。
              </div>
            </div>
            <div class="flex-row flex-align-start tips-list-item">
              <div class="safe-radio"></div>
              <div class="safe-text1">
                预约成功后请保持电话畅通，将有医院工作人员与您联系。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-video-content-footer-height"></div>
      <div class="flex-row-center no-video-content-footer">
        <div class="footer-button" @click="handleMake()">预约视频门诊</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatus } from "../../../api/serverApi.js";
export default {
  data() {
    return {
      tempDataParams: {},
      formClient: "",
    };
  },
  mounted() {
    this.formClient = this.$route.query.formClient || "";
    document.body.style.backgroundColor = "#fff";
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function handlePostMessage(e) {
		  uni.getEnv(function(res) {
            console.log('当前环境：' + JSON.stringify(res));
			uni.postMessage({
            data: {
                action: JSON.stringify(res)
            }
        });
        });
	  });
    });
    this.tempDataParams = this.$route.query;
  },
  methods: {
	//接收APP发送过来的消息
    handlePostMessage(evt) {
		 this.$nextTick(() => {
          document.addEventListener('UniAppJSBridgeReady', function handlePostMessage(e) {
			 uni.postMessage({
              data: {
                action: '2'+evt
              },
            });
          });
        });
     },
    handleMake() {
		const self = this
      this.judgeToken();
	 /*let url =
        "../order/no-make?doc_hos_id=" +
        this.tempDataParams.doc_hos_id +
        "&from_department=" +
        this.tempDataParams.from_department +
        "&order_source=" +
        this.tempDataParams.order_source +
        "&is_remote=1";
		 this.$nextTick(() => {
            // document.addEventListener('UniAppJSBridgeReady', function() {
            // 向 app 发送消息
            uni.postMessage({
              data: {
                action: "videoServer",
                url: url,
              },
            });
            if (self.tempDataParams.formClient == "wechat") {
              // uni.navigateBack()
              uni.navigateTo({
                url: url,
              });
			}
		});*/
    },
    judgeToken() {
      const self = this;
      let url =
        "../order/no-make?doc_hos_id=" +
        this.tempDataParams.doc_hos_id +
        "&from_department=" +
        this.tempDataParams.from_department +
        "&order_source=" +
        this.tempDataParams.order_source +
        "&is_remote=1";
      if (!this.tempDataParams.token) {
        this.$nextTick(() => {
          // document.addEventListener('UniAppJSBridgeReady', function() {
          // 向 app 发送消息
          uni.postMessage({
            data: {
              action: "loginAction",
              url: "../login/index",
            },
          });
          if (this.tempDataParams.formClient == "wechat") {
            // uni.navigateBack()
            uni.navigateTo({
              url: "../login/index",
            });
          }
        });
        return;
      }
      let data = {
        city: this.tempDataParams.city,
        token: this.tempDataParams.token.replace(/\s+/g, "+"),
      };
      getStatus(data).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            // document.addEventListener('UniAppJSBridgeReady', function() {
            // 向 app 发送消息
            uni.postMessage({
              data: {
                action: "videoServer",
                url: url,
				data:res.data
              },
            });
            if (self.tempDataParams.formClient == "wechat") {
              // uni.navigateBack()
              uni.navigateTo({
                url: url,
              });
            }
          });
        } else if(res.code == 100){
          self.$nextTick(() => {
            // document.addEventListener('UniAppJSBridgeReady', function() {
            // 向 app 发送消息
            uni.postMessage({
              data: {
                action: "loginAction",
                url: "../login/index",
				data:self.tempDataParams.token
              },
            });
            if (self.tempDataParams.formClient == "wechat") {
              // uni.navigateBack()
              uni.navigateTo({
                url: "../login/index",
              });
            }
          });
        }
      });
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>

<style lang="scss" scoped>
.no-video-content {
  &-temp {
    &-header {
      img {
        width: 550px;
        height: 350px;
      }
    }

    &-section {
      &-item {
        width: 680px;
        background: #f3f9ff;
        border-radius: 20px;
        padding: 30px;

        .item-title {
          height: 32px 28px;
          font-size: 30px;
          font-weight: bold;
          color: #000000;
          line-height: 36px;
        }

        .item-line {
          margin-top: 6px;
          width: 246px;
          height: 30px;
        }

        .tips-list {
          width: 100%;

          .tips-list-item {
            margin-top: 30px;

            .safe-icon {
              width: 35px;
              height: 41px;
            }

            .safe-text {
              margin-top: 4px;
              margin-left: 17px;
              // height: 30px;
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 36px;
            }

            .safe-radio {
              background-color: #585e6e;
              height: 10px;
              width: 10px;
              margin-top: 8px;
              border-radius: 50%;
            }

            .safe-text1 {
              width: 570px;
              margin-left: 22px;
              font-size: 26px;
              font-weight: 500;
              color: #585e6e;
              line-height: 36px;
            }
          }

          .tips-list-item:first-child {
            margin-top: 0;
          }
        }
      }

      &-item1 {
        margin-top: 20px;
        width: 680px;
        background: #fffaf3;

        padding-bottom: 28px;
        border-radius: 20px;

        .height-line {
          width: 100%;
          height: 12px;
          background: #ffdaaa;
          border-radius: 20px 20px 0 0;
        }

        .tips-list {
          margin-top: 32px;
          margin: 0 30px;

          .tips-list-item {
            margin-top: 2px;
            width: 100%;
            .item-title {
              height: 60px;
              font-size: 30px;
              font-weight: bold;
              color: #000000;
              line-height: 60px;
            }

            .item-value {
              height: 60px;
              font-weight: 400;
              font-size: 30px;
              color: #000000;
              line-height: 60px;
            }
          }
        }
      }
    }
  }

  &-footer {
    padding-top: 18px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
    height: 160px;
    background: #ffffff;
    box-shadow: 0px -10px 16px 0px rgba(50, 50, 50, 0.06);

    .footer-button {
      text-align: center;
      border-radius: 50px;
      width: 400px;
      height: 80px;
      line-height: 80px;
      background: #3ecd80;
      font-size: 32px;
      font-weight: 500;
      color: #ffffff;
    }
    &-height {
      height: 210px;
    }
  }
}
</style>
