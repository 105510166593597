<template>
  <div class="recruit-success-content">
    <div class="flex-column flex-align-center recruit-success-temp">
      <img class="recruit-success-temp-icon" src="../../assets/recruit/zm_chengg@3x.png" />
      <div class="recruit-success-temp-title">资料提交成功</div>
      <div class="recruit-success-temp-introduce">工作人员将会审核您的资料，敬 请留意通知！</div>
    </div>
    <div class="flex-row-center recruit-success-footer">
      <div class="footer-button" @click="handleBack()">回到首页</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formClient: "",
    };
  },
  mounted() {
    this.formClient = this.$route.query.formClient || "";

    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () {});
    });
  },
  methods: {
    handleBack() {
      this.$router.go(-3);
      return
      //等安卓ios上线后打开
      if (this.formClient == "bjAndroid") {
        try {
          $App.getDataFormVue(item.doc_id_dt);
        } catch (e) {
          window.webkit.messageHandlers.doctor.postMessage({
            pageType: "Home",
          });
        }
        return;
      } else if (this.formClient == "bjIOS") {
        window.webkit.messageHandlers.doctor.postMessage({
          pageType: "Home",
        });
        return;
      } else if (this.formClient == "h5") {
        uni.reLaunch({
          url: "pages/index/index",
        });
        return;
      }
      this.$nextTick(() => {
        // document.addEventListener('UniAppJSBridgeReady', function() {
        // 向 app 发送消息
        uni.postMessage({
          data: {
            action: "Home",
            url: "pages/index/index",
          },
        });
        if (this.tempDataParams.formClient == "wechat") {
          // uni.navigateBack()
          uni.reLaunch({
            url: "pages/index/index",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.recruit-success {
  &-temp {
    margin-top: 260px;
    &-icon {
      width: 280px;
      height: 280px;
    }
    &-title {
      margin-top: 70px;
      font-weight: bold;
      font-size: 36px;
      color: #3c81f3;
    }
    &-introduce {
      width: 437px;
      text-align: center;
      margin-top: 34px;
      font-weight: 500;
      font-size: 30px;
      color: #999999;
    }
  }
  &-footer {
    margin-top: 164px;
    .footer-button {
      width: 560px;
      height: 80px;
      line-height: 80px;
      background: #3c81f3;
      border-radius: 40px;
      font-weight: 500;
      font-size: 32px;
      color: #ffffff;
      letter-spacing: 2px;
      text-align: center;
    }
  }
}
</style>
