import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    doctorRegData: {
      doctorIntroduction: "",
      doctorGoodat: "",
      doctorThumb: "",
      IDCard: "",
      mobile: "",
      name: "",
      IDCardA: "",
      IDCardB: "",
      zcz: '',
      zyzA: '',
      zyzB: '',
      yszA: '',
      yszB: '',
    }
  },
  getters: {
    stateData(state) {
      return state.doctorRegData
    }
  },
  mutations: {
    editDoctorRegData(state,value){
      state.doctorRegData = value
      console.log(">>>>",state.doctorRegData )
    }
  },
  actions: {
  },
  modules: {
  }
})
