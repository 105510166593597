// var OSS = require('ali-oss');
import OSS from "ali-oss";
const accessKeyId = 'LTAI4GG8ZTTr6XH8KwhswMQD'//accessKeyId
const accessKeySecret = 'RytzbhrfMvW4wmQaloe8WtK0EVIftH'
const ossHostUrl = 'https://static.dtgh91.com/';
const bucket = 'dianpingstatic'
const endpoint = 'oss-accelerate.aliyuncs.com'//断点
const region = 'oss-cn-beijing'//断点
let date = new Date()
export function ossUpload(ossConfig, file, file_prefix = 'recruitment_data') {
  return new Promise(async (resolve, reject) => {
    var client = new OSS({
      region: region,
      accessKeyId: ossConfig.AccessKeyId || accessKeyId,
      accessKeySecret: ossConfig.AccessKeySecret || accessKeySecret,
      // stsToken: ossConfig.SecurityToken,
      bucket: bucket,
    })  //后端提供数据
    let objectKey = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    let imageName = `${date.getTime()}_${random_string(6)}`
    let fileTypeResult = ''
    if (file_prefix == 'recruitment_data') {
      switch (file.type) {
        case 'image/png':
          fileTypeResult = '.png'
          break;
        case 'image/jpeg':
          fileTypeResult = '.jpg'
          break;
        default:
          console.log("格式错误")
          break;
      }

    } else if (file_prefix == 'invoice_pdf') {
      fileTypeResult = '.pdf'
    }
    const isIMAGE = file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (file_prefix == 'medical_data' && !isIMAGE) {
      return false;
    }
    const isLt1M = file.size / 1024 / 1024 < 10;
    if (file_prefix == 'recruitment_data' && !isLt1M) {
      return false;
    }
    const fileName = `${file_prefix}/${objectKey}/${imageName}${fileTypeResult}`;  //定义唯一的文件名
    // console.log(fileName)
    client.put(fileName, file).then(({ res, url, name }) => {
      if (res && res.status == 200) {
        console.log(`阿里云OSS上传文件成功回调`, res, url, name);
        resolve({
          url: ossHostUrl + fileName,
          // id: fileName,
          name: file.name,
          uid: file.uid
        })
      }
    }).catch((err) => {
      console.log(`阿里云OSS上传失败回调`, err);
    });
  })
}
function random_string(len) {
  len = len || 32
  var chars = 'abcDEFGHJKMNPQRSTWXYZabcdefhijkmnPRstwxyz2345678'
  var maxpos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxpos))
  }
  return pwd
}
