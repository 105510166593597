<template>
  <div class="flex-column flex-align-center form-modal-content" v-if="showValue" @click="showValue = false">
    <div class="form-modal-temp" @click.stop>
      <div class="flex-column flex-align-center form-modal-temp-content">
        <div class="content-title">请入驻医生填写联系方式</div>
        <div class="content-list">
          <div class="flex-column content-list-item">
            <div class="flex-row-between flex-align-center item-form">
              <span class="title1">医生姓名</span>
              <input class="input1" v-model="tempData.name" placeholder="" />
            </div>
          </div>
          <div class="flex-column content-list-item">
            <div class="flex-row-between flex-align-center item-form">
              <span class="title1">手机号</span>
              <input class="input1" v-model="tempData.mobile" type="tel" placeholder="" />
            </div>
          </div>
          <div class="flex-column content-list-item">
            <div class="flex-row-between flex-align-center item-form">
              <span class="title1">验证码</span>
              <input class="input2" maxlength="4" type="tel" v-model="tempData.code" placeholder="" />
              <span class="code" v-show="isNaN(sendNum)" @click="getCode">获取验证码</span>
              <span class="code" v-show="!isNaN(sendNum)">{{ sendNum }}S后获取</span>
            </div>
          </div>
          <div class="flex-column content-list-item">
            <div class="flex-row-between flex-align-center item-form">
              <span class="title1">执业范围</span>
              <input class="input1" v-model="tempData.department" placeholder="" />
            </div>
          </div>
          <div class="flex-column content-list-item" style="height: auto">
            <div class="flex-cloumn item-form-textarea">
              <span class="title">医生接诊疾病</span>
              <textarea class="item-textarea" v-model="tempData.doctorGoodat" placeholder=""></textarea>
            </div>
          </div>
        </div>
        <button class="content-submit" :disabled="visibleSubmit" @click="handleSubmit()">
          <i v-if="visibleSubmit" class="el-icon-loading"></i>
          {{ visibleSubmit ? "提交中" : "确认提交" }}
        </button>
        <div class="content-tips">提交后由工作人员与您联系， 请注意保持电话畅通。</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
import { doctorReg, sendSmsData } from "../../api/recruitApi.js";
import md5 from "../../utils/md5.js";
export default {
  components: { Toast },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showValue: this.value,
      tempData: {
        name: "",
        mobile: "",
        type: 1,
        code:''
      },
      visibleSubmit: false,
      sendNum: "获取验证码",
      intervalTime: null,
    };
  },
  watch: {
    value(n, o) {
      this.showValue = n;
    },
    showValue(n, o) {
      this.$emit("input", n);
    },
  },
  methods: {
    checkData() {
      const self = this;
      if (!self.tempData.name) {
        Toast({
          message: "请输入医生姓名",
          duration: 5000,
        });
        return false;
      }
      if (!self.tempData.mobile) {
        Toast({
          message: "请输入手机号",
          duration: 5000,
        });
        return false;
      }
      if (!/^((1[3-9][0-9]))\d{8}$/.test(self.tempData.mobile)) {
        Toast({
          message: "请输入正确的手机号码",
          duration: 5000,
        });
        return false;
      }
      if (!self.tempData.department) {
        Toast({
          message: "请输入执业范围",
          duration: 5000,
        });
        return false;
      }
      if (!self.tempData.doctorGoodat) {
        Toast({
          message: "请输入擅长接诊疾病",
          duration: 5000,
        });
        return false;
      }
      
      if (self.tempData.code.length != 4) {
        Toast({
          message: "验证码不正确",
          duration: 5000,
        });
        return false;
      }
      return true;
    },
    checkCode() {
      const self = this;
      if (!self.tempData.mobile) {
        Toast({
          message: "请输入手机号",
          duration: 5000,
        });
        return false;
      }
      if (!/^((1[3-9][0-9]))\d{8}$/.test(self.tempData.mobile)) {
        Toast({
          message: "请输入正确的手机号码",
          duration: 5000,
        });
        return false;
      }
      return true;
    },
    //获取验证码
    getCode() {
      const self = this;
      if (!this.checkCode()) {
        return
      }
      if (!isNaN(this.sendNum)) {
        return;
      }
      this.sendNum = 60;
      this.intervalTime = setInterval(() => {
        if (self.sendNum > 1) {
          self.sendNum--;
        } else {
          self.sendNum = !self.codeMassage ? "重新获取?" : "获取验证码";
          clearInterval(self.intervalTime);
        }
      }, 1000);
      let data = {
        mobile: this.tempData.mobile,
        verify_code: md5(this.tempData.mobile + 'dengtadianping_sms'),
        verify_time: Date.now(),
        verify_appid: 4,
      };
      sendSmsData(data).then((res) => {
        if (res.data.code == 200) {
          Toast({
            message: "发送成功",
            duration: 5000,
          });
        }
      });
    },
    handleSubmit() {
      if (!this.checkData()) {
        return;
      }
      this.tempData["type"] = 1;
      this.visibleSubmit = true;
      doctorReg(this.tempData)
        .then((res) => {
          this.visibleSubmit = false;
          console.log("dddd");
          if (res.code == 200) {
            Toast({
              message: "提交成功",
              duration: 2000,
            });
            setTimeout(() => {
              this.tempData = {
                name: "",
                mobile: "",
                type: 1,
              };
            }, 1000);
            this.$emit("submit", this.tempData);
          }
        })
        .catch(() => {
          this.visibleSubmit = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-modal {
  &-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  &-temp {
    width: 600px;
    height: 1030px;
    background: #ffffff;
    border-radius: 19px;
    position: relative;
    top: 14%;
    &-content {
      margin: 40px 50px 0;
      .content-title {
        font-weight: 500;
        font-size: 32px;
        color: #1e2430;
        text-align: center;
        font-weight: bold;
        letter-spacing: 2px;
      }
      .content-list {
        margin-top: 26px;
        width: 100%;
        .content-list-item {
          margin-top: 10px;
          padding-top: 22px;
          background-color: #f1f1f1;
          border-radius: 16px;
          height: 90px;
          padding: 0 26px;
          .item-form {
            .title {
              width: 90px;
              height: 90px;
              font-weight: 400;
              font-size: 30px;
              color: #666666;
              line-height: 90px;
            }
            .title1 {
              width: 130px;
              height: 90px;
              font-weight: 400;
              font-size: 30px;
              color: #666666;
              line-height: 90px;
            }
            .input1 {
              margin-left: 16px;
              width: 276px;
              height: 60px;
              font-weight: 400;
              font-size: 32px;
              color: #1f1f1f;
              line-height: 60px;
              border: none;
              outline: none;
              background-color: #f1f1f1;
            }
            .input {
              margin-left: 16px;
              width: 338px;
              height: 60px;
              font-weight: 400;
              font-size: 32px;
              color: #1f1f1f;
              line-height: 60px;
              border: none;
              outline: none;
              background-color: #f1f1f1;
            }
            .input2 {
              width: 180px;
              height: 60px;
              font-weight: 400;
              font-size: 32px;
              color: #1f1f1f;
              line-height: 60px;
              border: none;
              outline: none;
              background-color: #f1f1f1;
            }
            .code {
              border: none;
              display: inline-block;
              width: 140px;
              font-size: 24px;
              height: 90px;
              font-weight: 400;
              color: #666666;
              line-height: 90px;
              text-align: center;
            }
            .code::after{
              border: none;
            }
          }
          .line-row {
            width: 100%;
            height: 1px;
            background: rgba(113, 118, 117, 0.2);
          }
          .item-form-textarea {
            width: 100%;
            .title {
              height: 42px;
              font-weight: 400;
              font-size: 30px;
              color: #666666;
              line-height: 42px;
            }
            .item-textarea {
              margin-top: 12px;
              width: 100%;
              display: block;
              height: 150px;
              background: #f1f1f1;
              border-radius: 12px;
              font-weight: 400;
              font-size: 28px;
              color: #1f1f1f;
              border: none;
              outline: none;
              padding: 0;
              padding-bottom: 16px;
            }
          }
        }
        .content-list-item:first-child {
          // padding: 0;
        }
      }
      .content-submit {
        margin-top: 40px;
        width: 420px;
        height: 74px;
        line-height: 74px;
        background: #3ecd80;
        border-radius: 37px;
        text-align: center;
        font-size: 30px;
        color: #ffffff;
        border: none;
        letter-spacing: 2px;
      }
      .content-submit::after {
        border: none;
      }
      .content-tips {
        width: 390px;
        margin-top: 36px;
        height: 63px;
        font-weight: 400;
        font-size: 28px;
        color: #ffa423;
        line-height: 36px;
        text-align: center;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
