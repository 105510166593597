<template>
  <div class="flex-column flex-align-center deficiencySuccess-content">
    <div class="deficiencySuccess-content-header">气虚体质测评</div>
    <div class="deficiencySuccess-content-border">
      <div class="deficiencySuccess-content-section">
        <div class="deficiencySuccess-content-section-temp">
          <div class="context" v-html="tempData.content"></div>
        </div>
      </div>
      <div class="square-content square-top-left">
        <div class="square-content-temp square-top-left-temp"></div>
        <div class="circular-temp"></div>
      </div>
      <div class="square-content square-top-right">
        <div class="square-content-temp square-top-right-temp"></div>
        <div class="circular-temp"></div>
      </div>
      <div class="square-content square-bottom-left">
        <div class="square-content-temp square-bottom-left-temp"></div>
        <div class="circular-temp"></div>
      </div>
      <div class="square-content square-bottom-right">
        <div class="square-content-temp square-bottom-right-temp"></div>
        <div class="circular-temp"></div>
      </div>
    </div>
    <div class="flex-column flex-align-center deficiencySuccess-content-tips" v-if="dataList.length > 0">
      <div class="flex-row-center flex-align-center deficiencySuccess-content-tips-temp">
        <div class="deficiencySuccess-content-tips-temp-title">相关热门医生</div>
      </div>
    </div>
    <div class="deficiencySuccess-content-doctor-list" v-if="dataList.length > 0">
      <div class="deficiencySuccess-content-doctor-list-item" v-for="(item, index) in dataList" :key="index">
        <div class="flex-row flex-align-start deficiencySuccess-content-doctor-list-item-details"
          @click="handleItem(item)">
          <div class="doctor-avatar">
              <img class="doctor-avatar" :src="item.thumb" width="100%"/>
              <div class="doctor-duodian" v-if="item.is_duodian==1">多点执业</div>
            </div>
          <div class="doctor-temp">
            <div class="flex-row flex-align-center doctor-details">
              <div class="name">{{ item.name }}</div>
              <div class="level">{{ item.title }}</div>
            </div>
            <div class="hospital-details">
              {{ item.hos_name }}
            </div>
            <div class="flex-row flex-align-center doctor-tags-list">
              <div class="doctor-tags-list-item" v-show="item.yibao">{{ item.yibao }}</div>
              <div class="doctor-tags-list-item" v-show="item.type">{{ item.type }}</div>
              <div class="doctor-tags-list-item" v-show="item.is_remote == 1">视频门诊</div>
              <div class="doctor-tags-list-item" v-show="item.tag">{{ item.tag }}</div>
            </div>
            <div class="introduce-details">
              <div class="context">
                {{ item.goodat }}
              </div>
              <div class="img-temp">
                <img width="100%" src="../../assets/deficiency/shanchang.png" />
              </div>
            </div>
            <div class="make-button">预约挂号</div>
          </div>
          <div class="flex-row-center flex-align-center switch-temp" @click.stop="handleSwitch">
            <div class="flex-row-center flex-align-center switch-icon">
              <img width="100%" src="../../assets/deficiency/switch-icon.png" />
            </div>
            <div class="switch-text">换一换</div>
          </div>
        </div>
      </div>
      <div style="height:44px"></div>
    </div>
  </div>
</template>
<script>
import deficiencyData from "../../data/deficiencyData.json";
import { getDoctorsRecommend } from "../../api/doctorApi.js";
export default {
  data() {
    return {
      tempData: {
        content: "",
      },
      curPage: 1,
      totalPage: 1, // 总页数
      dataList: [],
      tempDataParams: {},
      formClient: ''
    };
  },
  mounted() {
    this.sumNumber = this.$route.query.sum;
    this.tempDataParams = this.$route.query;
    this.formClient = this.$route.query.formClient || '';
    this.getList();
    document.body.style.backgroundColor = "#FFF4EA";
    this.getDoctorsList();
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () {
      });
    });
  },
  methods: {
    getList() {
      const self = this;
      if (self.sumNumber <= 29) {
        self.tempData = deficiencyData.answerList[0];
      } else {
        self.tempData = deficiencyData.answerList[1];
      }
    },
    getDoctorsList() {
      const self = this;
      this.tempDataParams["page"] = this.curPage;
      this.tempDataParams["pageNum"] = 1;
      getDoctorsRecommend(this.tempDataParams).then((res) => {
        self.totalPage = res.data.totalPage;
        self.dataList = res.data.dataList || [];
        console.log(">>", self.dataList);
      });
    },
    handleSwitch() {
      if (this.totalPage > this.curPage) {
        this.curPage++;
      } else {
        this.curPage = 1;
      }
      this.getDoctorsList();
    },
    handleItem(item) {
      if (this.formClient == 'bjAndroid') {
        //  window.webkit.messageHandlers.doctor.postMessage({docid: item.doc_id});
        try {
          $App.getDataFormVue(item.doc_id_dt);
        } catch (e) {
          window.webkit.messageHandlers.doctor.postMessage({ docid: item.doc_id_dt });
        }
        return
      } else if (this.formClient == 'bjIOS') {
        window.webkit.messageHandlers.doctor.postMessage({ docid: item.doc_id_dt });
        return
      } else if (this.formClient == "h5") {
        let url = this.FEServer + "doctor/details?doc_id=" + item.doc_id +
          "&order_source=ceping_" + this.tempDataParams.ceping_id;
        // window.location.replace(url)
        uni.navigateTo({
          url:
            "../doctor/details?doc_id=" + item.doc_id +
            "&order_source=ceping_" + this.tempDataParams.ceping_id,
        });
        return
      }
      this.$nextTick(() => {
        // document.addEventListener('UniAppJSBridgeReady', function() {
        // 向 app 发送消息
        uni.postMessage({
          data: {
            action: "doctorDetails",
            url: "../doctor/details?doc_id=" + item.doc_id + "&order_source=ceping_" + this.tempDataParams.ceping_id,
          },
        });
        if (this.tempDataParams.formClient == 'wechat') {
          uni.navigateTo({
            url: "../doctor/details?doc_id=" + item.doc_id + "&order_source=ceping_" + this.tempDataParams.ceping_id
          })
        }
      });
      // })
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss" scoped>
.deficiencySuccess {
  &-content {
    &-header {
      margin-top: 54px;
      width: 451px;
      height: 76px;
      background: url("../../assets/deficiency/header-temp.png") no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 76px;
      font-size: 32px;
      font-family: Source Han Serif SC;
      font-weight: bold;
      color: #8b5330;
    }

    &-border {
      position: relative;
      margin-top: 72px;
      width: 690px;

      .square-content {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #fff4ea;

        .square-content-temp {
          width: 23px;
          height: 23px;
          position: absolute;
          border: solid #debc97 2px;
        }

        .circular-temp {
          position: absolute;
          bottom: 18px;
          right: 18px;
          width: 8px;
          height: 8px;
          border-radius: 10px;
          background-color: #debc97;
        }
      }

      .square-top-left {
        top: -14px;
        left: -14px;

        .square-top-left-temp {
          bottom: 0px;
          right: 0px;
          border-left: none;
          border-top: none;
        }
      }

      .square-top-right {
        top: -14px;
        right: -14px;

        .square-top-right-temp {
          bottom: 0px;
          left: 0px;
          border-right: none;
          border-top: none;
        }
      }

      .square-bottom-left {
        bottom: -14px;
        left: -14px;

        .square-bottom-left-temp {
          top: 0px;
          right: 0px;
          border-left: none;
          border-bottom: none;
        }

        .circular-temp {
          top: 18px;
          right: 18px;
        }
      }

      .square-bottom-right {
        bottom: -14px;
        right: -14px;

        .square-bottom-right-temp {
          top: 0px;
          left: 0px;
          border-right: none;
          border-bottom: none;
        }

        .circular-temp {
          top: 18px;
          left: 18px;
        }
      }
    }

    &-section {
      //    width: 690px;
      background-color: #fcebd7;
      border: solid #debc97 2px;
      border-radius: 10px;
      padding: 10px;

      &-temp {
        background: linear-gradient(0deg, #fdf4eb 0%, #ffffff 100%);
        border: 3px solid #a5764f;
        z-index: 3;
        position: relative;

        .context {
          padding: 55px 45px;
          font-size: 28px;
          font-weight: 500;
          color: #5f5043;
          line-height: 44px;
        }
      }
    }

    &-tips {
      background: url("../../assets/deficiency/temp-shadow.png") no-repeat;
      background-size: 100% 100%;
      height: 169px;
      width: 710px;

      &-temp {
        margin-top: 56px;
        background: url("../../assets/deficiency/tuijian.png") no-repeat;
        background-size: 100%;
        width: 528px;
        height: 60px;

        &-title {
          height: 31px;
          line-height: 31px;
          font-size: 32px;
          font-family: Source Han Serif SC;
          font-weight: bold;
          color: #8b5330;
        }
      }
    }

    &-doctor {
      &-list {
        width: 750px;

        &-item {
          padding: 24px 28px;
          background: linear-gradient(0deg, #d2ae92 0%, #f8e4cc 100%);
          position: relative;
          &-details {
            margin-top: 22px;
            position: relative;

            .doctor-avatar {
            width: 100px;
            height: 100px;
            background-color: #F7F8FA;
            border-radius: 50%;
            position: relative;

            .doctor-duodian {
              position: absolute;
              bottom: -14px;
              width: 100px;
              height: 30px;
              line-height: 30px;
              background: linear-gradient(-90deg, #D09762 0%, #D6AC84 100%);
              border-radius: 15px;
              text-align: center;
              font-size: 20px;
              color: #6D3E30;
            }
          }

            .doctor-temp {
              margin-left: 22px;
              width: 530px;

              .doctor-details {
                margin-top: 4px;

                .name {
                  height: 32px;
                  line-height: 32px;
                  font-size: 32px;
                  font-family: Source Han Serif SC;
                  font-weight: bold;
                  color: #8b5330;
                }

                .level {
                  margin-left: 18px;
                  line-height: 29px;
                  font-size: 30px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #8b5330;
                }
              }

              .hospital-details {
                margin-top: 20px;
                height: 26px;
                line-height: 26px;
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #8b5330;
              }

              .doctor-tags-list {
                margin-top: 14px;

                .doctor-tags-list-item {
                  height: 38px;
                  padding: 0 14px;
                  background: linear-gradient(-90deg, #FF8042 0%, #FFA77B 100%);
                  border: 1px solid #FFFFFF;
                  border-radius: 8px;
                  line-height: 38px;
                  font-size: 28px;
                  font-weight: 500;
                  color: #FFFFFF;
                  margin-right: 20px;
                }

                .doctor-tags-list-item:last-child {
                  margin-right: 0;
                }
              }

              .introduce-details {
                // width: 538px;
                width: 470px;
                border-radius: 10px;
                background-color: #faebd9;
                margin-top: 20px;
                position: relative;
                padding: 16px 34px;

                // padding-right: 20px;
                .context {
                  // padding: 16px 34px;
                  // height: 103px;
                  font-size: 28px;
                  font-weight: 500;
                  color: #8b5330;
                  line-height: 38px;
                  white-space: normal;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  /* autoprefixer: off */
                  -webkit-line-clamp: 3; //只显示两行
                  /*autoprefixer: on */
                  overflow: hidden;
                }

                .img-temp {
                  position: absolute;
                  top: 0px;
                  left: -14px;
                  width: 38px;
                  height: 71px;
                }
              }

              .make-button {
                margin-top: 24px;
                margin-left: 46px;
                width: 300px;
                height: 80px;
                background: url("../../assets/deficiency/btn-bg.png") no-repeat;
                background-size: 100% 100%;
                line-height: 80px;
                text-align: center;
                font-size: 36px;
                font-weight: 500;
                color: #fff;
              }
            }

            .switch-temp {
              position: absolute;
              right: -10px;
              top: -20px;
              // width: 130px;
              padding: 8px 16px;
              height: 40px;
              background: #d5b195;
              border-radius: 10px;

              .switch-icon {
                width: 27px;
                height: 24px;
              }

              .switch-text {
                margin-left: 6px;
                height: 23px;
                line-height: 23px;
                font-size: 24px;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}</style>
