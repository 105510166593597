import { render, staticRenderFns } from "./DoctorList.vue?vue&type=template&id=6b958868&scoped=true&"
import script from "./DoctorList.vue?vue&type=script&lang=js&"
export * from "./DoctorList.vue?vue&type=script&lang=js&"
import style0 from "./DoctorList.vue?vue&type=style&index=0&id=6b958868&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b958868",
  null
  
)

export default component.exports