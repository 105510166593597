<template>
  <div class="flex-column flex-align-center popup-content" v-if="showValue" @click="showValue = false">
    <div class="popup-temp">
      <slot name="title"></slot>
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  components: { Toast },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showValue: this.value,
      tempData: {
        name: "",
        mobile: "",
      },
    };
  },
  watch: {
    value(n, o) {
      this.showValue = n;
    },
    showValue(n, o) {
      this.$emit("input", n);
    },
  },
  methods: {
    checkData() {
      const self = this;
      if (!self.tempData.name) {
        Toast({
          message: "请输入姓名",
          duration: 5000,
        });
        return false;
      }
      if (!self.tempData.mobile) {
        Toast({
          message: "请输入手机号",
          duration: 5000,
        });
        return false;
      }

      if (!/^((1[3-9][0-9]))\d{8}$/.test(self.tempData.mobile)) {
        Toast({
          message: "请输入正确的手机号码",
          duration: 5000,
        });
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (!this.checkData()) {
        return;
      }
      console.log(">>>>", this.tempData);
      this.$emit("submit", this.tempData);
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  &-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  &-temp {
    width: 100%;
    height: 70%;
    background: #ffffff;
    border-radius: 36px 36px 0 0;
    position: fixed;
    bottom: 0;
    font-size: 26px;
    .popup-title{
      margin-top: 10px;
      text-align: center;
      font-weight: 500;
      font-size: 36px;
      height: 100px;
      line-height: 100px;
      color: #000000;
    }
    .popup-context{
      margin: 0 32px;
      // margin-top: 40px;
      height: 100%;
      overflow: scroll;
    }
    .popup-height{
      height: 100px;
      width: 100%;
    }
  }
}
</style>
