import { render, staticRenderFns } from "./praise.vue?vue&type=template&id=67f87d37&scoped=true&"
import script from "./praise.vue?vue&type=script&lang=js&"
export * from "./praise.vue?vue&type=script&lang=js&"
import style0 from "./praise.vue?vue&type=style&index=0&id=67f87d37&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f87d37",
  null
  
)

export default component.exports