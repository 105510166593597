<template>
  <div class="index-content">
    <div class="flex-column flex-align-center index-login" v-if="!checkData">
      <div class="index-login-title">查看页面路径</div>
      <form class="index-login-form">
        <div class="form-item">账号：<input v-model="name" placeholder="请输入账号" /></div>
        <div class="form-item">密码：<input v-model="password" placeholder="请输入密码" /></div>
        <button class="form-button" type="submit" @click="submit">提交</button>
      </form>
    </div>
    <table border="1px" cellpadding="10px" v-if="checkData">
      <tr>
        <th>#</th>
        <th>标题</th>
        <th>页面路径</th>
        <th>访问路由</th>
      </tr>
      <tr v-for="(item, index) in routesList" :key="index">
        <td>{{ index }}</td>
        <td>{{ item.meta.title }}</td>
        <td>{{ item.component.__file }}</td>
        <td>{{ localUrl }}{{ item.path }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
import router from "@/router/index";
export default {
  data() {
    return {
      localUrl: "",
      routesList: [],
      checkData: false,
      name: "",
      password: "",
    };
  },
  mounted() {
    this.localUrl = window.location.host;
    this.routesList = router.options.routes;
    console.log("????", router.options.routes);
  },
  methods: {
    submit() {
      if (this.name == "kinsey" && this.password == "123456") {
        this.checkData = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1201px) {
  .index {
    &-login {
      margin: 0 auto;
      border: 1px solid #efefef;
      width: 140px;
      height: 140px;
      margin-top: 70px;
      &-title {
        margin-top: 6px;
        width: 140px;
        font-size: 8px;
        text-align: center;
      }
      &-form {
        margin-top: 30px;
        .form-item {
          height: 20px;
        }
        .form-button {
          height: 16px;
          line-height: 16px;
          width: 70px;
          font-size: 8px;
          border-radius: 4px;
          color: #fff;
          background-color: #1890ff;
          border-color: #1890ff;
        }
      }
    }
  }
}
// 如果文档宽度小于 1200px
@media only screen and (max-width: 1200px) {
  .index {
    &-login {
      margin: 0 auto;
      &-title {
        margin-top: 36px;
        font-size: 28px;
        text-align: center;
      }
      &-form {
        margin-top: 30px;
        .form-item {
            font-size: 28px;
          margin-top: 10px;
        }
        .form-button {
          width: 170px;
          height: 50px;
          font-size: 18px;
          border-radius: 4px;
          color: #fff;
          background-color: #1890ff;
          border-color: #1890ff;
        }
      }
    }
  }
}
</style>
