<template>
  <div class="ranking-evaluate-content">
    <div class="ranking-evaluate-header">
      <img
        class="ranking-evaluate-header-img"
        width="100%"
        :src="dataInfo.rank_pic?dataInfo.rank_pic:require('../../assets/ranking/top_haoping@3x.png')"/>
    </div>
    <div class="ranking-evaluate-doctor">
      <evaluate-ranking-doctor
        @handleItem="handleItem"
        class="ranking-evaluate-doctor-list"
        :dataList="dataList"
      />
      <!-- <div style="height:22px"></div> -->
    </div>
  </div>
</template>
<script>
import { getRankingDoctorList } from "../../api/rankingApi.js";
import evaluateRankingDoctor from "../../components/ranking/EvaluateDoctorList.vue";
export default {
  components: { evaluateRankingDoctor },
  data() {
    return {
      tempDataParams: {},
      formClient: "",
      dataList: [],
      dataInfo:{rank_pic:""}
    };
  },
  mounted() {
    this.tempDataParams = this.$route.query;
    this.formClient = this.$route.query.formClient || "";
    this.getDoctorsList();
    document.body.style.backgroundColor = "#EFE5D3";
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function() {});
    });
  },
  methods: {
    getDoctorsList() {
      const self = this;
      this.tempDataParams["page"] = this.curPage;
      this.tempDataParams["pageNum"] = 10;
      getRankingDoctorList(this.tempDataParams).then((res) => {
        self.dataList = res.data.dataList || [];
        self.dataInfo = res.data.dataInfo || {rank_pic:""};
        document.title = self.dataInfo.title;
      });
    },
    handleItem(item) {
      if (this.formClient == "bjAndroid") {
        try {
          $App.getDataFormVue(item.doc_id_dt);
        } catch (e) {
          window.webkit.messageHandlers.doctor.postMessage({
            docid: item.doc_id_dt,
          });
        }
        return;
      } else if (this.formClient == "bjIOS") {
        window.webkit.messageHandlers.doctor.postMessage({
          docid: item.doc_id_dt,
        });
        return;
      }else if(this.formClient == "h5"){
         let url = this.FEServer+"doctor/details?doc_id="+item.doc_id +"&order_source=rank_" + this.tempDataParams.rank_id;
          // window.location.replace(url) 
          uni.navigateTo({
            url:
              "../doctor/details?doc_id="+item.doc_id +"&order_source=rank_" + this.tempDataParams.rank_id
          });
          return
      }
      this.$nextTick(() => {
        // document.addEventListener('UniAppJSBridgeReady', function() {
        // 向 app 发送消息
        uni.postMessage({
          data: {
            action: "doctorDetails",
            url:
              "../doctor/details?doc_id=" +
              item.doc_id +
              "&order_source=rank_" +
              this.tempDataParams.rank_id,
          },
        });
        if (this.tempDataParams.formClient == "wechat") {
          uni.navigateTo({
            url: "../doctor/details?doc_id=" +item.doc_id +"&order_source=rank_" +this.tempDataParams.rank_id,
          });
        }
      });
      // })
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss" scoped>
.ranking-evaluate {
  &-header {
    &-img {
      width: 100%;
      height: 410px;
    }
  }
  &-doctor {
    position: relative;
    top: -28px;
    margin: 0 20px;
    &-list {
    }
  }
  &-content {
  }
}
</style>
