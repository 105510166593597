import Cookies from 'js-cookie'
const TokenKey = 'editDoctorRegData'
const ChooseImage = 'ChooseImage'
export function getDoctorRegData() {
    return Cookies.get(TokenKey)
}

export function setDoctorRegData(token) {
    return Cookies.set(TokenKey, token)
}

export function removeDoctorRegData() {
    return Cookies.remove(TokenKey)
}
export function getChooseImage() {
    return Cookies.get(ChooseImage) || false
}

export function setChooseImage(data) {
    return Cookies.set(ChooseImage, data)
}

export function removeChooseImage() {
    return Cookies.remove(ChooseImage)
}