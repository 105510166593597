<template>
  <div class="flex-column flex-align-center breast-details-content">
     <img class="header-img" width="100%" src="@/assets/breast/top_images@3x.png"/>
     <div class="breast-details-content-temp">
       <div class="breast-details-content-temp-list">
          <div class="flex-column flex-align-center breast-details-content-temp-list-item">
              <div class="header-tips-img">
                  <img width="100%" src="@/assets/breast/zuijiashiqi@3x.png"/>
              </div>
              <div class="header-tips-content">
                  <b>每次月经后的7～10天是作乳房自我检查的最佳时期，</b>例如你是1号来月经的，那么7-10号自查。<b>绝经后的女性可每月同一天进行自我检一次，</b>发现异常及时就医。
              </div>
          </div>
          <div class="flex-column flex-align-center breast-details-content-temp-list-item" v-for="(item,index) in itemList" :key="index">
              <div class="header-item-img">
                  <img width="100%" :src="item.titleImg"/>
              </div>
              <div class="section-list"  v-if="item.handleList.length>0">
                <div class="section-list-item" :class="item.typeStyle+'-style'" v-for="(handleItem,handleIndex) in item.handleList" :key="handleIndex">
                    <div class="header-title-img">
                      <img width="100%" :src="handleItem.titleImg" />
                    </div>
                    <div class="header-content">
                      <div class="context" v-html="handleItem.content"></div>
                    </div>
                </div>
              </div>
              <div class="tips-content" v-if="item.tipsContent">
                <div class="content-temp" v-html="item.tipsContent"></div>
              </div>
          </div>
       </div>
     </div>
     <div class="breast-details-content-footer">
       <div class="footer-button" @click="handleIntroduce()">查看健康指南</div>
     </div>
  </div>
</template>
<script>
export default {
  name:'BreastDetails',
  data() {
    return {
      itemList:[
        {
          titleImg:require('../../assets/breast/kan@3x.png'),
          typeStyle:'none',
          handleList:[{
            titleImg:require('@/assets/breast/one@3x.png'),
            content:'面对镜子双手下垂，仔细观察乳房两边是否大小对称，乳头是否有凹陷（先天大小乳、乳头凹陷者属发育异常）。'
          },{
            titleImg:require('@/assets/breast/two@3x.png'),
            content:'将双臂高举过头，仔细观察两侧乳房的形状、轮廓有无变化；<br/>乳房皮肤有无红肿、皮肤皱褶、脱皮、糜烂、橘皮样改变等异常；<br/>观察乳头是否在同一水平线上，是否有抬高、回缩。'
          },{
            titleImg:require('@/assets/breast/three@3x.png'),
            content:'放下两臂，双手叉腰，两肘努力向后，使胸部肌肉绷紧，观察两侧乳房是否等高、对称，乳头、乳晕和皮肤有无异常。'
          },]
        },
        {
          titleImg:require('../../assets/breast/chu@3x.png'),
          typeStyle:'chu',
          handleList:[{
            titleImg:require('@/assets/breast/shoushi@3x.png'),
            content:'四指伸直、指腹平触，滑行触诊，注意不要遗漏任何部位。不要用指尖压或是挤捏！'
          },{
            titleImg:require('@/assets/breast/fangshi@3x.png'),
            content:'左手上提至头部后侧，用右手检查左乳，以手指之指腹轻压乳房，感觉是否有硬块，由乳头开始做环状顺时针方向检查，逐渐向外(约三四圈)，至全部乳房检查完为止，用同样方法检查右边乳房。'
          },{
            titleImg:require('@/assets/breast/tiwei@3x.png'),
            content:'站立位、坐位、平卧位都可以，方便即可。'
          },
          ]
        },
         {
          titleImg:require('../../assets/breast/ji@3x.png'),
          typeStyle:'ji',
          handleList:[],
          tipsContent:'检查完乳房后，亦须检查腋下有无淋巴肿大      (因为如果有病症，淋巴结肿大首先就会蔓延至腋窝下)。<br/>最后再用食指和中指轻轻挤压乳头，观察是否有异常分泌物。根据发生溢液的双乳还是仅一便乳房、单孔还是多孔、溢液的颜色及质地、有无基础疾病（如垂体微腺瘤）、有无服药（如抗精神病类药物），必要时结合辅助检查去医院评估情况。'
        }
      ],
      tempDataParams:{},
    };
  },
  mounted(){
    console.log('parmas',this.$route)
    this.tempDataParams = this.$route.query
    this.$nextTick(() => {
      // 初始化uni.webview
      document.addEventListener('UniAppJSBridgeReady', function () {
        console.log('初始化uniapp的API成功');
        /* eslint-disable*/
      });
    });
  },
  methods:{
    handleIntroduce(){
      this.$router.push({path:"/BreastIntroduce",query:this.tempDataParams})
    }
  },
  beforeCreate () {
    document.title = this.$route.meta.title
  }
};
</script>
<style lang="scss">
.breast-details-content{
  background: #FFE4E1;
  &-temp{
    &-list{
      &-item{
          margin-top: 44px;
          width: 710px;
          position: relative;
           .header-tips-img{
              position:absolute;
              top: -28px;
              width: 440px;
              height: 80px;
            }
            .header-tips-content{
              background: #fff;
              padding: 58px 34px;
              padding-top: 88px;
              font-size: 32px;
              color: #374D9B;
              line-height: 60px;
              border-radius: 40px;
              b{
                background-image: linear-gradient(#FFF 60%, #F3DADD 30%);
              }
            }
            .header-item-img{
              position:absolute;
              top: -28px;
              width: 256px;
              height: 91px;
            }
            .section-list,.tips-content{
              background-color: #fff;
              border-radius: 40px;
              padding: 30px;
              padding-top: 88px;
              .section-list-item{
                margin-top: 52px;
                width: 100%;
                position: relative;
                .header-title-img{
                  top: -37px;
                  left: 34px;
                  position:absolute;
                  width: 85px;
                  height: 91px;
                }
                .header-content{
                  width: 100%;
                  background: #EDF1F7;
                  border-radius: 20px;
                  font-size: 28px;
                  font-weight: 500;
                  color: #374D9B;
                  line-height: 40px;
                  .context{
                     padding: 77px 28px 24px;
                  }
                }
              }
              .chu-style{
                margin-top: 70px;
                 .header-title-img{
                  width: 194px;
                  height: 88px;
                }
              }
              .content-temp{
                width: 645px;
                font-size: 30px;
                font-weight: 500;
                color: #374D9B;
                line-height: 56px;
              }
            }
         }
    }
  }
  &-footer{
    height: 232px;
    .footer-button{
      margin: 44px;
      width: 360px;
      height: 90px;
      line-height: 90px;
      background: #FDABB3;
      border-radius: 45px;
      font-size: 38px;
      font-family: TsangerYuMo;
      font-weight: bold;
      color: #104BA0;
      text-align: center;
    }
  }
}
</style>
