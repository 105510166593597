<template>
  <div class="doctor-list-components">
    <div
      class="doctor-list-item"
      :class="index + 1 == dataList.length ? 'last-item' : ''"
      v-for="(item, index) in dataList"
      :key="index"
      @click="handleItem(item)"
    >
      <div class="flex-row flex-align-start doctor-list-item-temp">
        <div class="flex-row-center flex-align-center doctor-ranking">
          <div class="ranking-number">
            {{ index + 4 < 10 ? "0" + (index + 4) : index + 4 }}
          </div>
        </div>
        <img class="doctor-avatar" :src="item.thumb" />
        <div class="item-temp">
          <div class="flex-row-between  flex-align-center doctor-details">
            <div class="flex-row flex-align-center">
              <div class="doctor-title">{{ item.name }}</div>
              <div class="doctor-level">{{ item.title }}</div>
            </div>
          </div>
          <div class="flex-row flex-align-center hospital-details">
            <div class="flex-row flex-align-center price-temp">
              <div class="price-title">专享挂号费</div>
              <div class="price-value">
                <b>￥</b>{{ item.price_discount || 0 }}
              </div>
              <div class="price-discount">￥{{ item.price || 0 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row-between flex-align-center grade-details">
        <div class="flex-row flex-align-center grade-details-item">
          <div class="grade-details-item-title">预约量</div>
          <div class="grade-details-item-value">{{item.booking_nums}}</div>
        </div>
        <div class="flex-row flex-align-center grade-details-item">
          <div class="grade-details-item-title">热度</div>
          <div class="grade-details-item-value">{{item.likes}}</div>
        </div>
        <div class="flex-row flex-align-center grade-details-item">
          <div class="grade-details-item-title">专业</div>
          <div class="grade-details-item-value">{{item.score_skills*10}}</div>
        </div>
        <div class="flex-row flex-align-center grade-details-item">
          <div class="grade-details-item-title">口碑</div>
          <div class="grade-details-item-value">{{item.goods}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleItem(item) {
      this.$emit("handleItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.doctor-list {
  &-components{
      width: 100%;
    .last-item{
    //   border-radius: 0 0 40px 40px;
    }
  }
  &-item {
    // height: 249px;
    padding: 28px 18px 0;
    height: 230px;
    &-temp {
      .doctor-ranking{
        width: 46px;
        height: 55px;
        margin-right: 20px;
        .ranking-icon{
          width: 46px;
          height: 55px;
        }
        .ranking-number{
          height: 55px;
          line-height: 55px;
          font-size: 30px;
          font-weight: bold;
          color: #5B5ADB;
        }
      }
      .doctor-avatar {
        width: 120px;
        height: 120px;
        border-radius: 16px;
        border:2px solid #f0f8fc;
      }
      .item-temp {
        width: 474px;
        margin-left: 20px;
      }
      .doctor-details {
         margin-top: 6px;
        .doctor-title {
          height: 36px;
          line-height: 36px;
          font-size: 36px;
          font-weight: bold;
          color: #1F1F1F;
        }
        .doctor-level {
          margin-left: 18px;
          height: 30px;
          line-height: 30px;
          font-size: 30px;
          font-weight: 500;
          color: #1F1F1F;
        }
         .make-temp {
          .title {
            height: 22px;
            font-size: 22px;
            font-weight: 400;
            color: #1F1F1F;
            line-height: 30px;
          }
        }
      }
      .hospital-details {
        margin-top: 40px;
        .price-temp {
			.price-title {
				height: 26px;
				font-size: 26px;
				font-weight: 500;
				color: #14181E;
				line-height: 26px;
			}

			.price-value {
				margin-left: 14px;
				height: 28px;
				font-size: 36px;
				font-weight: bold;
				color: #FF5722;
				line-height: 28px;
        b{
          font-size: 20px;
          height: 36px;
          line-height: 36px;
        }
			}

			.price-discount {
				margin-left: 8px;
				font-size: 24px;
				height: 24px;
				line-height: 24px;
				font-weight: 500;
				text-decoration: line-through;
				color: #999999;
			}
		 }
      }
    }
    .grade-details {
        margin-left: 79px;
        margin-top: 30px;
        margin-right: 23px;
        .grade-details-item {
          .grade-details-item-title {
            height: 24px;
            font-size: 24px;
            font-weight: 500;
            color: #666666;
            line-height: 24px;
          }
          .grade-details-item-value {
            margin-left: 4px;
            height: 30px;
            font-size: 30px;
            font-weight: bold;
            color: #605cde;
            line-height: 30px;
          }
        }
        .grade-details-item:first-child {
            margin-top: 0;
        }
     }
  }

  &-item:nth-child(odd){
    background: #ffffff;
  }
  &-item:nth-child(even){
    background: #EEF4FD;
  }
  &-item:first-child{
    border-radius: 32px 32px 0 0;
    padding-top: 36px;
  }
}
</style>
