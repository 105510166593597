<template>
  <div class="ranking-list-content">
    <div
      class="ranking-list-item"
      v-for="(item, index) in dataList"
      :key="index" 
    >
    <a class="ranking-list-item-img" :href="item.url">
      <img class="ranking-list-item-img" :src="item.img" width="100%" />
    </a>
    </div>
  </div>
</template>
<script>
import { getRankList } from "../../api/rankingApi.js";
export default {
  data() {
    return {
      dataList: [
        {
          rank_id:1005,
          img: "https://pic.dtgh91.com/rank/ranking-list-02@3x.png",
          title: "年度人气榜单",
          url:'/ranking/RankingPopularity?rank_id=1005&city=1'
        },
        {
          rank_id:1003,
          img: "https://pic.dtgh91.com/rank/ranking-list-01@3x.png",
          title: "珍藏好医榜",
          url:'/ranking/RankingFollow?rank_id=1003&city=1'
        },
        {
          rank_id:1004,
          img: "https://pic.dtgh91.com/rank/ranking-list-03@3x.png",
          title: "实力口碑榜",
          url:'/ranking/RankingPraise?rank_id=1004&city=1'
        },
      ],
      tempDataParams: {},
      curPage:1
    };
  },
  mounted() {
    console.log("parmas", this.$route);
    this.tempDataParams = this.$route.query;
    document.body.style.backgroundColor = "#fff";
    this.getList();
    this.$nextTick(() => {
      // 初始化uni.webview
      document.addEventListener("UniAppJSBridgeReady", function() {
        console.log("初始化uniapp的API成功");
        /* eslint-disable*/
      });
    });
  },
  methods: {
    handleItem(item) {
      let query = this.getUrlParam(item.url)
      // this.$router.push({
      //   path: item.url
      // , query: query
      // });
    },
    getUrlParam(url){
      let arrObj = url.split("?")
      let params = Object.create(null)
      if (arrObj.length>1){
        arrObj=arrObj[1].split("&");
        arrObj.forEach(item=>{
          item = item.split("=")
          params[item[0]]=item[1]
        })
      }
      return params
    },
    getList() {
      const self = this;
      this.tempDataParams["page"] = this.curPage;
      this.tempDataParams["pageNum"] = 10;
      getRankList(this.tempDataParams).then((res) => {
        self.dataList = res.data || []
        self.dataList.forEach(item=>{
          item.url+=(item.url.indexOf("?")<=-1?'?':'&')+'formClient='+this.tempDataParams.formClient
        })
      });
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss" scoped>
.ranking-list {
  &-content {
    padding: 0 20px;
  }
  &-item {
    margin-top: 20px;
    width: 100%;
    height: 240px;
    a{
      text-decoration:none;
      display: block;
      border-radius: 10px;
      -webkit-tap-highlight-color:rgba(255,0,0,0);
    }
    &-img {
      height: 240px;
      width: 100%;
    }
  }
}
</style>
