<template>
  <div class="recruit-step2-content">
    <div class="recruit-step2-temp">
      <div class="header-tips">请填写真实有效的信息，平台将保护您的隐私</div>
      <div class="flex-row-between flex-align-center header-proces">
        <div class="flex-column flex-align-center header-proces-item">
          <div class="item-number">01</div>
          <div class="item-text">基础信息</div>
        </div>
        <div class="line-dashed"></div>
        <div class="flex-column flex-align-center header-proces-item active-item">
          <div class="item-number">02</div>
          <div class="item-text">资质认证</div>
        </div>
        <div class="line-dashed"></div>
        <div class="flex-column flex-align-center header-proces-item">
          <div class="item-number">03</div>
          <div class="item-text">完成</div>
        </div>
      </div>
      <div class="recruit-step2-temp-list">
        <div class="flex-row header-custom">上传执业医师证书<span>*</span></div>
        <div class="header-introduction">请上传有效证件照片，确保证件边框完整，字体清晰</div>
        <div class="flex-row-between flex-align-center temp-info-card">
          <div class="temp-info-card-item">
            <imageUpload @changeFile="changeFile" :styleType="'zyzA'" :dataValue="tempData.zyzA" />
          </div>
          <div class="temp-info-card-item">
            <imageUpload @changeFile="changeFile" :styleType="'zyzB'" :dataValue="tempData.zyzB" />
          </div>
        </div>
        <div class="flex-row-between flex-align-center temp-info-example">
          <div class="item-example">
            <img src="../../assets/recruit/shili_01@3x.png" />
            <span>首页示例图</span>
          </div>
          <div class="item-example">
            <img src="../../assets/recruit/shili_02@3x.png" />
            <span>内页示例图</span>
          </div>
        </div>
      </div>
      <div class="recruit-step2-temp-list">
        <div class="flex-row header-custom">上传医师资格证书<span>*</span></div>
        <div class="header-introduction">请上传有效证件照片，确保证件边框完整，字体清晰</div>
        <div class="flex-row-between flex-align-center temp-info-card">
          <div class="temp-info-card-item">
            <imageUpload @changeFile="changeFile" :styleType="'yszA'" :dataValue="tempData.yszA" />
          </div>
          <div class="temp-info-card-item">
            <imageUpload @changeFile="changeFile" :styleType="'yszB'" :dataValue="tempData.yszB" />
          </div>
        </div>
        <div class="flex-row-between flex-align-center temp-info-example">
          <div class="item-example">
            <img src="../../assets/recruit/shili_03@3x.png" />
            <span>首页示例图</span>
          </div>
          <div class="item-example">
            <img src="../../assets/recruit/shili_04@3x.png" />
            <span>内页示例图</span>
          </div>
        </div>
      </div>
      <div class="recruit-step2-temp-list">
        <div class="flex-row header-custom">上传医生职称证书<span>*</span></div>
        <div class="header-introduction">请上传有效证件照片，确保证件边框完整，字体清晰</div>
        <div class="flex-row-between flex-align-center temp-info-card">
          <div class="temp-info-card-item">
            <imageUpload @changeFile="changeFile" :styleType="'zcz'" :dataValue="tempData.zcz" />
          </div>
        </div>
      </div>
      <div class="recruit-step1-height"></div>
    </div>

    <div class="flex-column flex-align-center recruit-step2-footer">
      <div class="flex-row flex-align-center footer-checkbox">
        <el-checkbox v-model="tempData.checked"><span style="color: #1f1f1f">我已阅读并接受</span></el-checkbox>
        <div><a target="_blank" @click="(popupVisible = true), (dataType = 2)">《服务协议》</a>及<a target="_blank" @click="(popupVisible = true), (dataType = 1)">《隐私政策》</a></div>
      </div>
      <button class="footer-button" :disabled="visibleSubmit" @click="handleSubmit()">
        <i v-if="visibleSubmit" class="el-icon-loading"></i>
        {{ visibleSubmit ? "提交中" : "确认提交" }}
      </button>
    </div>
    <popup v-model="popupVisible" position="bottom">
      <div slot="title" class="popup-title">{{ dataType == "1" ? "隐私保护政策" : "医院用户服务协议" }}</div>
      <div slot="content" class="popup-context">
        <div v-if="dataType == 1" v-html="dadaText.privacy"></div>
        <div v-if="dataType == 2" v-html="dadaText.agreement"></div>
      </div>
    </popup>
  </div>
</template>
<script>
import imageUpload from "../../components/ImageUpload/index";
import { doctorReg, getText } from "../../api/recruitApi.js";
import popup from "../../components/modal/popup";
import { Toast } from "mint-ui";
import { getDoctorRegData, setDoctorRegData, removeDoctorRegData } from "../../store/cookie";
export default {
  components: { imageUpload, Toast, popup },
  data() {
    return {
      tempData: {
        checked: true,
        doctorIntroduction: "",
        doctorGoodat: "",
        doctorThumb: "",
        IDCard: "",
        mobile: "",
        name: "",
        IDCardA: "",
        IDCardB: "",
        zcz: "",
        zyzA: "",
        zyzB: "",
        yszA: "",
        yszB: "",
      },
      popupVisible: false,
      dadaText: {
        agreement: "",
        privacy: "",
      },
      dataType: 1,
      visibleSubmit: false,
    };
  },
  created() {
    this.getTextData();
  },
  mounted() {
    if (getDoctorRegData()) {
      this.tempData = JSON.parse(getDoctorRegData());
    }
    this.tempData.zcz = this.$route.query.zcz || "";
    this.tempData.zyzA = this.$route.query.zyzA || "";
    this.tempData.zyzB = this.$route.query.zyzB || "";
    this.tempData.yszA = this.$route.query.yszA || "";
    this.tempData.yszB = this.$route.query.yszB || "";
  },
  methods: {
    getTextData() {
      getText().then((res) => {
        this.dadaText = res.data;
      });
    },
    checkData() {
      const self = this;
      if (!self.tempData.IDCardA || !self.tempData.IDCardB) {
        Toast({
          message: "请上传身份证正反面",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.name) {
        Toast({
          message: "请输入姓名",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.IDCard) {
        Toast({
          message: "请输入身份证号",
          duration: 1200,
        });
        return false;
      }
      if (/^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/.test(self.tempData.IDCard) == false) {
        Toast({
          message: "身份证号格式错误",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.mobile) {
        Toast({
          message: "请输入手机号",
          duration: 1200,
        });
        return false;
      }

      if (!/^((1[3-9][0-9]))\d{8}$/.test(self.tempData.mobile)) {
        Toast({
          message: "请输入正确的手机号码",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.doctorThumb) {
        Toast({
          message: "请上传头像",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.doctorGoodat) {
        Toast({
          message: "请输入擅长疾病",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.doctorIntroduction) {
        Toast({
          message: "请输入个人简介",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.zyzA || !self.tempData.zyzB) {
        Toast({
          message: "请上传执业医师证书正反面",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.yszA || !self.tempData.yszB) {
        Toast({
          message: "请上传医师资格证正反面",
          duration: 1200,
        });
        return false;
      }
      if (!self.tempData.zcz) {
        Toast({
          message: "请上传医生职称证书",
          duration: 1200,
        });
        return false;
      }
      return true;
    },
    handleSubmit() {
      console.log(">>>", this.tempData);
      if (!this.checkData()) {
        return;
      }
      if (!this.tempData.checked) {
        Toast({
          message: "请仔细阅读并勾选相关协议与政策",
          duration: 1200,
        });
        return false;
      }
      this.visibleSubmit = true;
      this.tempData["type"] = 2;
      doctorReg(this.tempData).then((res) => {
        this.visibleSubmit = false;
        if (res.code == 200) {
          this.$router.push({ path: "/recruitSuccess", query: this.tempData });
        }
      }).catch(()=>{
        this.visibleSubmit = false;
        
      });
    },
    changeFile(url, type) {
      console.log(">>url, type>", url, type);
      if (type == "zcz") {
        this.tempData.zcz = url;
      } else if (type == "zyzA") {
        this.tempData.zyzA = url;
      } else if (type == "zyzB") {
        this.tempData.zyzB = url;
      } else if (type == "yszA") {
        this.tempData.yszA = url;
      } else if (type == "yszB") {
        this.tempData.yszB = url;
      }
      console.log(">>>>", this.tempData);
    },
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="scss" scoped>
.recruit-step2 {
  &-temp {
    padding: 0 20px;
    background-color: #f6f8fa;
    .header-custom {
      font-weight: 500;
      font-size: 36px;
      color: #101010;
      span {
        margin-top: 6px;
        margin-left: 16px;
        width: 17px;
        height: 17px;
        font-weight: bold;
        font-size: 36px;
        color: #f58040;
        line-height: 40px;
      }
    }
    .header-introduction {
      margin-top: 22px;
      font-weight: 500;
      font-size: 26px;
      color: #f58040;
    }
    .header-tips {
      padding: 0 10px;
      height: 80px;
      line-height: 80px;
      background: #f6f8fa;
      font-weight: 500;
      font-size: 28px;
      color: #666666;
    }
    .header-proces {
      background-color: #fff;
      height: 160px;
      border-radius: 16px;
      .header-proces-item {
        width: 120px;
        margin: 0 22px;
        .item-number {
          width: 50px;
          height: 50px;
          line-height: 50px;
          border-radius: 50%;
          font-size: 28px;
          background-color: #f3f5f9;
          color: #999999;
          text-align: center;
        }
        .item-text {
          margin-top: 20px;
          height: 28px;
          line-height: 28px;
          font-weight: 500;
          font-size: 28px;
          color: #101010;
        }
      }
      .line-dashed {
        width: 80px;
        margin-bottom: 40px;
        border: 1px dashed #d1d1d1;
      }
      .active-item {
        .item-number {
          background: #ffe4d6;
          color: #f58040;
        }
      }
    }
    &-list {
      margin-top: 20px;
      background: #ffffff;
      border-radius: 16px;
      padding: 40px 30px 30px;
      .temp-info-card {
        margin-top: 32px;
        .temp-info-card-item {
        }
      }
      .temp-info-example {
        margin-top: 40px;
        .item-example {
          position: relative;
          width: 310px;
          height: 240px;
          img {
            width: 310px;
            height: 240px;
          }
          span {
            position: absolute;
            top: 100px;
            left: 65px;
            height: 40px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 6px;
            font-size: 30px;
            line-height: 40px;
            color: #ffffff;
            padding: 0 14px;
          }
        }
      }
    }
  }
  &-height {
    height: 240px;
  }
  &-footer {
    background: #ffffff;
    position: fixed;
    bottom: 0;
    height: 220px;
    width: 100%;
    .footer-checkbox {
      padding-left: 80px;
      margin-top: 24px;
      font-weight: 500;
      font-size: 24px;
      color: #1f1f1f;
      width: 100%;
      .el-checkbox .el-checkbox__label span {
        font-size: 24px !important;
      }
      a {
        font-size: 24px;
        color: #1b81ff;
        text-decoration: none;
      }
    }
    .footer-button {
      margin-top: 22px;
      width: 690px;
      height: 80px;
      line-height: 80px;
      background: #3c81f3;
      border-radius: 40px;
      font-weight: 500;
      font-size: 36px;
      color: #ffffff;
      text-align: center;
      border: none;
    }
    .footer-button::after {
      border: none;
    }
  }
}
</style>
