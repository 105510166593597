<template>
  <div class="recruit-poster-content">
    <div class="recruit-poster-img">
      <img class="header-img" src="@/assets/recruit/zm_images@3x.png" />
      <div class="recruit-poster-img-list">
        <img class="item-img" :src="item.url" v-for="(item, index) in imgList" :key="index" />
      </div>
    </div>
    <div class="recruit-poster-height"></div>
    <div class="flex-row-between flex-align-center recruit-poster-footer">
      <div class="footer-button" @click="formModal = true">联系我们</div>
      <div class="footer-button" @click="handleSubmit()">立即入驻</div>
    </div>
    <formModal v-model="formModal" @submit="handleDoctorReg" />
  </div>
</template>
<script>
import formModal from "../../components/modal/formModal.vue";
import { Toast } from "mint-ui";

export default {
  components: { formModal, Toast },
  data() {
    return {
      imgList: [
        { url: require("@/assets/recruit/zm_ljdt@3x.png"), height: "573px" },
        { url: require("@/assets/recruit/zm_liucheng@3x.png"), height: "713px" },
        { url: require("@/assets/recruit/zm_shipin@3x.png"), height: "760px" },
        { url: require("@/assets/recruit/zm_tuwen@3x.png"), height: "760px" },
        { url: require("@/assets/recruit/zm_zaixiankaifang@3x.png"), height: "720px" },
        { url: require("@/assets/recruit/zm_fukuan@3x.png"), height: "720px" },
        { url: require("@/assets/recruit/zm_fehuo@3x.png"), height: "560px" },
        { url: require("@/assets/recruit/zm_fuzhen@3x.png"), height: "420px" },
      ],
      formModal: false,
      tempDataParams: {},
    };
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
  },
  methods: {
    handleSubmit() {
      this.$router.push({ path: "/recruitStep1", query: this.tempDataParams });
    },
    handleDoctorReg(data) {
      setTimeout(() => {
        this.formModal = false;
      }, 1000);
    },
    //提交
    //提交数据
  },
};
</script>
<style lang="scss" scoped>
.recruit-poster {
  &-content {
    background-color: #c3f6ff;
  }
  &-height {
    height: 190px;
  }
  &-img {
    .header-img {
      margin-top: 108px;
      margin-left: 16px;
      width: 734px;
      height: 610px;
    }

    &-list {
      padding: 0 20px;
      .item-img {
        width: 100%;
        height: 573px;
        margin: 0;
        padding: 0;
        display: inherit;
      }
      .item-img:nth-child(1) {
        height: 573px;
        margin-top: 48px;
      }
      .item-img:nth-child(2) {
        height: 713px;
        margin-top: 52px;
      }
      .item-img:nth-child(3) {
        height: 760px;
      }
      .item-img:nth-child(4) {
        height: 760px;
      }
      .item-img:nth-child(5) {
        height: 720px;
      }
      .item-img:nth-child(6) {
        height: 720px;
      }
      .item-img:nth-child(7) {
        height: 560px;
      }
      .item-img:nth-child(8) {
        height: 420px;
      }
    }
  }
  &-footer {
    background: #ffffff;
    position: fixed;
    bottom: 0;
    height: 160px;
    width: 100%;
    .footer-button:nth-child(1) {
      width: 280px;
      height: 80px;
      line-height: 80px;
      background: linear-gradient(270deg, #3ecd80 0%, #71e9c6 100%);
      border-radius: 40px;
      font-weight: 500;
      font-size: 36px;
      color: #ffffff;
      text-align: center;
      margin-left: 45px;
    }
    .footer-button:last-child {
      width: 360px;
      height: 80px;
      line-height: 80px;
      background: linear-gradient(270deg, #ff8921 0%, #ffd222 100%);
      border-radius: 40px;
      font-weight: 500;
      font-size: 36px;
      color: #ffffff;
      text-align: center;
      margin-right: 45px;
    }
  }
}
</style>
